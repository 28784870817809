import invariant from 'tiny-invariant';
import { v4 as uuidV4 } from 'uuid';

import { defaultColors } from 'business/data-analysis/pages/graph/components/graph-card/constants';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';

import { GraphPageState } from './../types';

const getNewColor = (
  graph: Parameters<GraphPageState['structure']['graphs']['set']>[1],
) => {
  const parameters = Array.from(graph.parameters.values());

  const lastDefaultColorUsedIndex = parameters.findLastIndex((param) =>
    defaultColors.includes(param.color),
  );
  const lastColor = parameters.at(lastDefaultColorUsedIndex)?.color ?? 0;

  const newColorIndex =
    (defaultColors.findIndex((defaultColor) => defaultColor === lastColor) +
      1) %
    defaultColors.length;

  return defaultColors[newColorIndex];
};

export const addParameters = (
  graphSet: GraphPageState,
  graphId: string,
  parameters: { id: number; name: string; unit: string }[],
): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const graphToModify = graphs.get(graphId);
  invariant(graphToModify, 'Trying to add a parameter to a non-existant graph');

  const ringParameterProperties =
    graphSet.structure.type === GraphSetTypeEnum_Enum.Ring
      ? { aggregationType: 'avg', computeType: 'total' }
      : undefined;

  parameters.forEach(({ id, name, unit }) =>
    graphToModify.parameters.set(uuidV4(), {
      id,
      name,
      unit,
      color: getNewColor(graphToModify),
      ...ringParameterProperties,
    }),
  );

  graphs.set(graphId, graphToModify);

  return {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  };
};
