import { RingPerDayGraphPoint } from 'business/production-and-performance/pages/ring-per-day/types';
import { RangeFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider/types';
import { getMissingDatesInArray } from 'technical/time-utils/get-missing-dates-in-arrray';

import { getEmptyGraphPoint } from './getEmptyGraphPoint';

// Durring the developpement, we found a strange bug when there is only 2 values
// with a 2 days interterval between.
// The only fix was to add the missing days with values at 0
// This also completed the missing days within a selected day range
// Beware, the input ringPerDayGraphData is altered
export const addMissingDays = (
  filters: RangeFilters,
  ringPerDayGraphData: RingPerDayGraphPoint[],
) => {
  const ringPerDayClone = structuredClone(ringPerDayGraphData);

  if (
    filters.filterType === 'date' &&
    filters.dateRange &&
    filters.dateRange[0] &&
    filters.dateRange[1]
  ) {
    const [startDate, endDate] = filters.dateRange;

    const missingDates = getMissingDatesInArray(
      ringPerDayGraphData.map(({ date }) => date),
      startDate.toDate(),
      endDate.toDate(),
    );
    const missingValues = missingDates.map(getEmptyGraphPoint);
    ringPerDayClone.push(...missingValues);
  }

  return ringPerDayClone;
};
