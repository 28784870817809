import { gql } from '@apollo/client';

const UserPermissionsFieldsFragment = gql`
  fragment UserPermissionsFields on userPermission {
    id
    userId
    module
    permissionType {
      id
      name
    }
  }
`;

const ConstructionSiteFieldsFragment = gql`
  fragment ConstructionSiteFields on constructionSite {
    id
    name
    logo
    managerValidationRequired
    isManagerRequiredInReport
    isEngineerRequiredInReport
    nbDaysPerWeek
    timeZone
    isCompleted
    isAutofillEnabled
    aggregatedReportActivityLevel
    nbTotalRings
    distanceUnit
    ringLengthCode0
    ringLengthCode1
    nbKeyPosition
    keyPositionPrefix
    tunnelBoringMachineName
    enabledModules @include(if: $isDataManager) {
      id
      module
    }
    userPermissions(
      where: {
        userId: { _eq: $userId }
        permissionType: {
          name: {
            _in: [
              "construction-site-manager"
              "construction-site-full-reader"
              "construction-site-reader"
              "user"
            ]
          }
        }
      }
    ) {
      ...UserPermissionsFields
    }
  }
  ${UserPermissionsFieldsFragment}
`;

const CommonUserFieldsFragment = gql`
  fragment CommonUserFields on user {
    id
    authId
    firstName
    lastName
    email
    isActive
    createdAt
    updatedAt
  }
`;

export const QUERY_MY_USER = gql`
  query QueryMyUser($id: String, $email: String) {
    user(
      where: {
        _or: [
          { authId: { _eq: $id } }
          { _and: [{ email: { _eq: $email } }, { authId: { _is_null: true } }] }
        ]
      }
    ) {
      ...CommonUserFields
    }
  }
  ${CommonUserFieldsFragment}
`;

export const QUERY_CONSTRUCTION_SITE_LIST = gql`
  query ConstructionSiteList($userId: uuid!, $isDataManager: Boolean!) {
    constructionSiteList: constructionSite {
      ...ConstructionSiteFields
    }
  }
  ${ConstructionSiteFieldsFragment}
`;

export const MUTATION_UPDATE_USER_AUTHID = gql`
  mutation UpdateUserAuthId($id: uuid!) {
    # Used to update user in order to add Auth0 id for admin created user.
    # An update in '_set' is required to trigger the hasura preset who update authId
    update_user(_set: { updatedAt: "now()" }, where: { id: { _eq: $id } }) {
      returning {
        ...CommonUserFields
      }
      affected_rows
    }
  }
  ${CommonUserFieldsFragment}
`;
