import {
  AgBarSeriesTooltipRendererParams,
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
  AgTooltipRendererResult,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { TFunction, useTranslation } from 'translations/hooks';

import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { parseAndFormatRingDuration } from 'business/production-and-performance/services/ring-duration';
import { GetExcavationAndBuildingDurationResponseDto } from 'generated/apiSchemas';
import { parseDurationStringToMilliseconds } from 'technical/time-utils';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import { NumberRangeValue } from 'ui/form/numberRangeInput';

interface ExcavationBuildTimeGraphProps {
  excavationBuildTimeGraphData: GetExcavationAndBuildingDurationResponseDto['graphData'];
}
export const TIME_DAY_HOUR_MINUTE_FORMAT = 'DD:HH:mm';

const hoursToMilliseconds = (hours: number | null | undefined) => {
  if (isUndefinedOrNull(hours)) {
    return undefined;
  }

  return hours * 3.6 * 10 ** 6;
};

const renderDurationString = ({
  datum,
  yKey,
}: AgBarSeriesTooltipRendererParams) => {
  return {
    content: parseAndFormatRingDuration(datum[yKey]),
  } satisfies AgTooltipRendererResult;
};

const excavationBuildTimeSeriesRealDurationsOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'realExcavationDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.realExcavationDuration',
      ),
      stackGroup: 'Real',
      tooltip: {
        renderer: renderDurationString,
      },
    },
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'realBuildDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.realBuildDuration',
      ),
      stackGroup: 'Real',
      tooltip: {
        renderer: renderDurationString,
      },
    },
  ] satisfies AgCartesianSeriesOptions[];

const excavationBuildTimeSeriesTotalDurationsOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'totalExcavationDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.totalExcavationDuration',
      ),
      stackGroup: 'Total',
      tooltip: {
        renderer: renderDurationString,
      },
    },
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'totalBuildDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.totalBuildDuration',
      ),
      stackGroup: 'Total',
      tooltip: {
        renderer: renderDurationString,
      },
    },
  ] satisfies AgCartesianSeriesOptions[];

const excavationBuildTimeAxesOptions = ({
  durationInterval,
}: {
  durationInterval: NumberRangeValue | undefined;
}) =>
  [
    {
      type: 'category',
      position: 'bottom',
    },
    {
      type: 'number',
      position: 'left',
      label: {
        formatter: ({ value }) => parseAndFormatRingDuration(value),
      },
      min: hoursToMilliseconds(durationInterval?.[0]),
      max: hoursToMilliseconds(durationInterval?.[1]),
    },
  ] satisfies AgCartesianAxisOptions[];

const formatGraphDataWithDuration = (
  excavationBuildTimeGraphData: GetExcavationAndBuildingDurationResponseDto['graphData'],
) =>
  excavationBuildTimeGraphData.map(
    ({
      ringNumber,
      totalExcavationDuration,
      realExcavationDuration,
      totalBuildDuration,
      realBuildDuration,
    }) => {
      return {
        ringNumber: ringNumber,
        totalExcavationDuration: parseDurationStringToMilliseconds(
          totalExcavationDuration,
        ),
        realExcavationDuration: parseDurationStringToMilliseconds(
          realExcavationDuration,
        ),
        totalBuildDuration:
          parseDurationStringToMilliseconds(totalBuildDuration),
        realBuildDuration: parseDurationStringToMilliseconds(realBuildDuration),
      };
    },
  );

export const ExcavationBuildTimeGraph = ({
  excavationBuildTimeGraphData,
}: ExcavationBuildTimeGraphProps) => {
  const { t } = useTranslation();
  const filters = useProdAndPerfFilters();
  const showTotalDurations = filters?.showTotalDurations;

  const realDurationsSeries = excavationBuildTimeSeriesRealDurationsOptions(t);
  const series = realDurationsSeries;

  if (showTotalDurations) {
    const totalDurationsSeries =
      excavationBuildTimeSeriesTotalDurationsOptions(t);
    series.push(...totalDurationsSeries);
  }

  const chartOptions = {
    data: formatGraphDataWithDuration(excavationBuildTimeGraphData),
    series,
    axes: excavationBuildTimeAxesOptions({
      durationInterval: filters.durationInterval,
    }),
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
};
