import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';

import { useExcavationBuildTimeExport } from './use-excavation-build-time-export';

export const ExcavationBuildTimeExport = () => {
  const { t } = useTranslation();
  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No constructionSite id');
  const { dayMode, showTotalDurations } = useProdAndPerfFilters();

  const { generateExport, fetchRange, messageContextHolder } =
    useExcavationBuildTimeExport();

  const onClick = () => {
    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: dayMode,
        fetchTotalDurations: showTotalDurations ?? false,
        locale: getLocale(),
        ...fetchRange,
      },
    });
  };

  return (
    <>
      {messageContextHolder}
      <Button icon={<ExportOutlined />} iconPosition="end" onClick={onClick}>
        {t('common.export')}
      </Button>
    </>
  );
};
