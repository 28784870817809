import { useLoaderData } from 'react-router-dom';

import { dataAnalysisGraphPageLoader } from 'business/data-analysis/pages/graph';
import { LoaderData } from 'technical/router/types';

export const useMode = () => {
  // Cast to get the correct type instead of unknown
  // It corresponds to the signature of the function that gives the data
  const { mode } = useLoaderData() as LoaderData<
    typeof dataAnalysisGraphPageLoader
  >;

  return mode;
};
