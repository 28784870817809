import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, Space, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';

import { notEmpty } from 'technical/utils/not-empty';

import styles from './index.module.scss';

export type NumberRangeValue =
  | [number | null | undefined, number | null | undefined]
  | null;

export interface NumberRangeInputProps {
  value?: NumberRangeValue;
  onChange?: (value: NumberRangeValue) => void;
  leftInputProps?: InputNumberProps;
  leftTitle?: string;
  rightInputProps?: InputNumberProps;
  rightTitle?: string;
  errorMessage?: string;
  className?: string;
}

function NumberRangeInput({
  value,
  onChange,
  leftInputProps,
  leftTitle,
  rightInputProps,
  rightTitle,
  errorMessage,
  className,
}: Readonly<NumberRangeInputProps>) {
  const leftInputId = `leftInput-${leftTitle}`;
  const rightInputId = `rightInput-${rightTitle}`;

  return (
    <Space
      direction={'vertical'}
      className={classNames(styles.rangeInputContainer, className)}
    >
      <Space
        className={classNames(
          styles.rangeInput,
          styles.customPicker,
          //custom picker is duplicate of  'ant-input ant-picker ant-picker-range ant-picker-outlined',
        )}
      >
        <Space>
          {leftTitle ? (
            <label htmlFor={leftInputId}>
              <Typography.Text type="secondary">{leftTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            className={styles.inputNumber}
            {...leftInputProps}
            variant={'borderless'}
            size={'small'}
            id={leftTitle ? leftInputId : undefined}
            value={notEmpty(value?.[0]) ? value[0] : undefined}
            onChange={(inputValue) => {
              if (!onChange) {
                return;
              }

              if (typeof inputValue !== 'string') {
                onChange([inputValue, value ? value[1] : undefined]);
              }
              onChange([undefined, value ? value[1] : undefined]);
            }}
          />
        </Space>
        <SwapRightOutlined
          className={
            styles.customPickerSeparator
            // duplicate of native ant class 'ant-picker-separator'
          }
        />
        <Space>
          {rightTitle ? (
            <label htmlFor={rightInputId}>
              <Typography.Text type="secondary">{rightTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            {...rightInputProps}
            id={rightTitle ? rightInputId : undefined}
            value={notEmpty(value?.[1]) ? value[1] : undefined}
            variant={'borderless'}
            size={'small'}
            className={styles.inputNumber}
            onChange={(inputValue) => {
              if (!onChange) {
                return;
              }

              if (typeof inputValue !== 'string') {
                onChange([value ? value[0] : undefined, inputValue]);
              }
              onChange([value ? value[0] : undefined, undefined]);
            }}
          />
        </Space>
      </Space>
      {errorMessage ? (
        <Typography.Text type="danger">{errorMessage}</Typography.Text>
      ) : null}
    </Space>
  );
}

export default NumberRangeInput;
