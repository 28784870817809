import { getAllDatesInRange } from './get-all-dates-in-range';

function outerDateIntersect(array1: Date[], array2: Date[]) {
  return [
    ...array1.filter((x) => !array2.some((y) => x.valueOf() === y.valueOf())),
    ...array2.filter((x) => !array1.some((y) => x.valueOf() === y.valueOf())),
  ];
}

export const getMissingDatesInArray = (
  compareArray: Date[],
  startDate: Date,
  endDate: Date,
): Date[] => {
  const allTheDates = getAllDatesInRange(startDate, endDate);
  return outerDateIntersect(compareArray, allTheDates);
};
