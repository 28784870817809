import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { TFunction, useTranslation } from 'translations/hooks';

import { formatGraphDateAxisLabel } from 'business/production-and-performance/services/graph-date-format';

type ProgressHistoryGraphProps = {
  progressHistoryGraphData: {
    date: Date;
    count: number;
  }[];
};

const tooltipRenderer = (params: any, t: TFunction) => {
  return {
    content: t('productionAndPerformance.ringPerDay.graph.tooltip.ring', {
      count: parseInt(params.datum[params.yKey]),
    }),
  };
};

const progressHistoryGraphSeriesOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'count',
      tooltip: { renderer: (params) => tooltipRenderer(params, t) },
    },
  ] satisfies AgCartesianSeriesOptions[];

const progressHistoryGraphAxesOptions = () =>
  [
    {
      type: 'time',
      position: 'bottom',
      nice: false,
      label: {
        formatter: ({ value }) => formatGraphDateAxisLabel(value),
      },
    },
    {
      type: 'number',
      position: 'left',
      nice: false,
      interval: {
        step: 1,
      },
    },
  ] satisfies AgCartesianAxisOptions[];

export function ProgressHistoryGraph({
  progressHistoryGraphData,
}: ProgressHistoryGraphProps) {
  const { t } = useTranslation();

  const chartOptions = {
    data: progressHistoryGraphData,
    axes: progressHistoryGraphAxesOptions(),
    series: progressHistoryGraphSeriesOptions(t),
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
}
