import { EditOutlined } from '@ant-design/icons';
import { ColumnType, ColumnsType } from 'antd/lib/table/interface';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  useIsConstructionSiteManager,
  usePermissions,
} from 'business/contextProviders/usePermissions';
import { RingDuration } from 'business/production-and-performance/pages/ring-table/components/ring-table/ring-duration';
import { useFormatMetricPoint } from 'business/production-and-performance/pages/ring-table/hooks/use-format-metric-point';
import { parseRingTimeToLocalizedString } from 'business/production-and-performance/pages/ring-table/services/ring-time';
import {
  RingTableColumnKeys,
  RingTableRow,
} from 'business/production-and-performance/pages/ring-table/types';
import { Module_Enum } from 'generated/graphql';

export const useRingTableColumns = (
  onEditionClick: (id: string | null) => void,
): ColumnsType<RingTableRow> => {
  const { t } = useTranslation();
  const { formatMetricPoint } = useFormatMetricPoint();

  const { currentConstructionSite } = useAppContext();
  const { isDataManager } = usePermissions();
  const isConstructionSiteManager = useIsConstructionSiteManager(
    Module_Enum.ProdPerf,
  );

  const edit = (record: RingTableRow) => {
    onEditionClick(record.id);
  };

  const columns: ColumnsType<RingTableRow> = [
    {
      title: t('productionAndPerformance.ring.table.ringNumber'),
      dataIndex: RingTableColumnKeys.RingNumber,
      key: RingTableColumnKeys.RingNumber,
      align: 'center',
    },
    {
      title: t('productionAndPerformance.ring.table.keyPosition'),
      dataIndex: RingTableColumnKeys.KeyPosition,
      key: RingTableColumnKeys.KeyPosition,
      render: (_, { keyPosition }) =>
        (currentConstructionSite?.keyPositionPrefix || '') + keyPosition,
      align: 'center',
    },
    {
      title: t('productionAndPerformance.ring.table.length'),
      dataIndex: RingTableColumnKeys.Length,
      key: RingTableColumnKeys.Length,
      render: (_, { length }) => {
        if (length === 2 && currentConstructionSite?.ringLengthCode1) {
          return currentConstructionSite.ringLengthCode1;
        }
        if (length === 1 && currentConstructionSite?.ringLengthCode0) {
          return currentConstructionSite.ringLengthCode0;
        }
        return '-';
      },
      align: 'center',
    },
    {
      title: t('productionAndPerformance.ring.table.buildEndMetricPoint'),
      dataIndex: RingTableColumnKeys.BuildEndMetricPoint,
      key: RingTableColumnKeys.BuildEndMetricPoint,
      render: (_, { buildEndMetricPoint }) =>
        formatMetricPoint(buildEndMetricPoint),
      align: 'center',
    },
    {
      title: t('productionAndPerformance.ring.table.excavation'),
      key: 'excavation',
      children: [
        {
          title: t('productionAndPerformance.ring.table.start'),
          dataIndex: RingTableColumnKeys.ExcavationStart,
          key: RingTableColumnKeys.ExcavationStart,
          render: (_, { excavationStart }) =>
            parseRingTimeToLocalizedString(excavationStart),
          align: 'center',
        },
        {
          title: t('productionAndPerformance.ring.table.end'),
          dataIndex: RingTableColumnKeys.ExcavationEnd,
          key: RingTableColumnKeys.ExcavationEnd,
          render: (_, { excavationEnd }) =>
            parseRingTimeToLocalizedString(excavationEnd),
          align: 'center',
        },
        {
          title: t('productionAndPerformance.ring.table.totalDuration'),
          dataIndex: RingTableColumnKeys.TotalExcavationDuration,
          key: RingTableColumnKeys.TotalExcavationDuration,
          render: (_, { totalExcavationDuration }) => (
            <RingDuration rawRingDuration={totalExcavationDuration} />
          ),
          align: 'center',
        },
        {
          title: t('productionAndPerformance.ring.table.realDuration'),
          dataIndex: RingTableColumnKeys.RealExcavationDuration,
          key: RingTableColumnKeys.RealExcavationDuration,
          render: (_, { realExcavationDuration }) => (
            <RingDuration rawRingDuration={realExcavationDuration} />
          ),
          align: 'center',
        },
      ],
    },
    {
      title: t('productionAndPerformance.ring.table.build'),
      key: 'build',
      children: [
        {
          title: t('productionAndPerformance.ring.table.start'),
          dataIndex: RingTableColumnKeys.BuildStart,
          key: RingTableColumnKeys.BuildStart,
          render: (_, { buildStart }) =>
            parseRingTimeToLocalizedString(buildStart),
          align: 'center',
        },
        {
          title: t('productionAndPerformance.ring.table.end'),
          dataIndex: RingTableColumnKeys.BuildEnd,
          key: RingTableColumnKeys.BuildEnd,
          render: (_, { buildEnd }) => parseRingTimeToLocalizedString(buildEnd),
          align: 'center',
        },
        {
          title: t('productionAndPerformance.ring.table.totalDuration'),
          dataIndex: RingTableColumnKeys.TotalBuildDuration,
          key: RingTableColumnKeys.TotalBuildDuration,
          render: (_, { totalBuildDuration }) => (
            <RingDuration rawRingDuration={totalBuildDuration} />
          ),
          align: 'center',
        },
        {
          title: t('productionAndPerformance.ring.table.realDuration'),
          dataIndex: RingTableColumnKeys.RealBuildDuration,
          key: RingTableColumnKeys.RealBuildDuration,
          render: (_, { realBuildDuration }) => (
            <RingDuration rawRingDuration={realBuildDuration} />
          ),
          align: 'center',
        },
      ],
    },
  ];

  if (isConstructionSiteManager || isDataManager) {
    columns.push({
      title: t('productionAndPerformance.ring.table.action.title'),
      key: 'editionColumn',
      render: (_, record) => {
        return <EditOutlined onClick={() => edit(record)} />;
      },
    } satisfies ColumnType<RingTableRow>);
  }

  return columns;
};
