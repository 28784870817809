/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type DataAnalysisControllerGraphByPeriodError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerGraphByPeriodVariables = {
  body: Schemas.GetGraphByPeriodDto;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerGraphByPeriod = (
  variables: DataAnalysisControllerGraphByPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GraphByPeriodResponseDto,
    DataAnalysisControllerGraphByPeriodError,
    Schemas.GetGraphByPeriodDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/graph-by-period',
    method: 'post',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerGraphByPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GraphByPeriodResponseDto,
      DataAnalysisControllerGraphByPeriodError,
      DataAnalysisControllerGraphByPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GraphByPeriodResponseDto,
    DataAnalysisControllerGraphByPeriodError,
    DataAnalysisControllerGraphByPeriodVariables
  >({
    mutationFn: (variables: DataAnalysisControllerGraphByPeriodVariables) =>
      fetchDataAnalysisControllerGraphByPeriod({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DataAnalysisControllerGraphByRingError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerGraphByRingVariables = {
  body: Schemas.GetGraphByRingDto;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerGraphByRing = (
  variables: DataAnalysisControllerGraphByRingVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GraphByRingResponseDto,
    DataAnalysisControllerGraphByRingError,
    Schemas.GetGraphByRingDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/graph-by-ring',
    method: 'post',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerGraphByRing = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GraphByRingResponseDto,
      DataAnalysisControllerGraphByRingError,
      DataAnalysisControllerGraphByRingVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GraphByRingResponseDto,
    DataAnalysisControllerGraphByRingError,
    DataAnalysisControllerGraphByRingVariables
  >({
    mutationFn: (variables: DataAnalysisControllerGraphByRingVariables) =>
      fetchDataAnalysisControllerGraphByRing({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DataAnalysisControllerSaveGraphSetError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerSaveGraphSetVariables = {
  body: Schemas.SaveGraphSetDto;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerSaveGraphSet = (
  variables: DataAnalysisControllerSaveGraphSetVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.SaveGraphSetResponse,
    DataAnalysisControllerSaveGraphSetError,
    Schemas.SaveGraphSetDto,
    {},
    {},
    {}
  >({ url: '/data-analysis/graph-set', method: 'post', ...variables, signal });

export const useDataAnalysisControllerSaveGraphSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SaveGraphSetResponse,
      DataAnalysisControllerSaveGraphSetError,
      DataAnalysisControllerSaveGraphSetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.SaveGraphSetResponse,
    DataAnalysisControllerSaveGraphSetError,
    DataAnalysisControllerSaveGraphSetVariables
  >({
    mutationFn: (variables: DataAnalysisControllerSaveGraphSetVariables) =>
      fetchDataAnalysisControllerSaveGraphSet({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DataAnalysisControllerDuplicateGraphSetQueryParams = {
  id: string;
};

export type DataAnalysisControllerDuplicateGraphSetError =
  Fetcher.ErrorWrapper<{
    status: 409;
    payload: {
      /**
       * @example 409
       */
      statusCode: number;
      /**
       * @example Conflict
       */
      message: string;
      /**
       * @example Conflict
       */
      error?: string;
    };
  }>;

export type DataAnalysisControllerDuplicateGraphSetVariables = {
  queryParams: DataAnalysisControllerDuplicateGraphSetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerDuplicateGraphSet = (
  variables: DataAnalysisControllerDuplicateGraphSetVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GraphSetStructureDto,
    DataAnalysisControllerDuplicateGraphSetError,
    undefined,
    {},
    DataAnalysisControllerDuplicateGraphSetQueryParams,
    {}
  >({
    url: '/data-analysis/duplicate-graph-set',
    method: 'post',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerDuplicateGraphSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GraphSetStructureDto,
      DataAnalysisControllerDuplicateGraphSetError,
      DataAnalysisControllerDuplicateGraphSetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GraphSetStructureDto,
    DataAnalysisControllerDuplicateGraphSetError,
    DataAnalysisControllerDuplicateGraphSetVariables
  >({
    mutationFn: (variables: DataAnalysisControllerDuplicateGraphSetVariables) =>
      fetchDataAnalysisControllerDuplicateGraphSet({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GraphSetExportControllerGenerateGraphSetPeriodExportError =
  Fetcher.ErrorWrapper<undefined>;

export type GraphSetExportControllerGenerateGraphSetPeriodExportVariables = {
  body: Schemas.GenerateGraphSetPeriodExportDto;
} & ApiContext['fetcherOptions'];

export const fetchGraphSetExportControllerGenerateGraphSetPeriodExport = (
  variables: GraphSetExportControllerGenerateGraphSetPeriodExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    GraphSetExportControllerGenerateGraphSetPeriodExportError,
    Schemas.GenerateGraphSetPeriodExportDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/generate-graph-set-period-export',
    method: 'post',
    ...variables,
    signal,
  });

export const useGraphSetExportControllerGenerateGraphSetPeriodExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      GraphSetExportControllerGenerateGraphSetPeriodExportError,
      GraphSetExportControllerGenerateGraphSetPeriodExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    GraphSetExportControllerGenerateGraphSetPeriodExportError,
    GraphSetExportControllerGenerateGraphSetPeriodExportVariables
  >({
    mutationFn: (
      variables: GraphSetExportControllerGenerateGraphSetPeriodExportVariables,
    ) =>
      fetchGraphSetExportControllerGenerateGraphSetPeriodExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GraphSetExportControllerGenerateGraphSetRingExportError =
  Fetcher.ErrorWrapper<undefined>;

export type GraphSetExportControllerGenerateGraphSetRingExportVariables = {
  body: Schemas.GenerateGraphSetRingExportDto;
} & ApiContext['fetcherOptions'];

export const fetchGraphSetExportControllerGenerateGraphSetRingExport = (
  variables: GraphSetExportControllerGenerateGraphSetRingExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    GraphSetExportControllerGenerateGraphSetRingExportError,
    Schemas.GenerateGraphSetRingExportDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/generate-graph-set-ring-export',
    method: 'post',
    ...variables,
    signal,
  });

export const useGraphSetExportControllerGenerateGraphSetRingExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      GraphSetExportControllerGenerateGraphSetRingExportError,
      GraphSetExportControllerGenerateGraphSetRingExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    GraphSetExportControllerGenerateGraphSetRingExportError,
    GraphSetExportControllerGenerateGraphSetRingExportVariables
  >({
    mutationFn: (
      variables: GraphSetExportControllerGenerateGraphSetRingExportVariables,
    ) =>
      fetchGraphSetExportControllerGenerateGraphSetRingExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RingListControllerGenerateRingListByDateExportError =
  Fetcher.ErrorWrapper<undefined>;

export type RingListControllerGenerateRingListByDateExportVariables = {
  body: Schemas.GenerageRingListByDateExportDto;
} & ApiContext['fetcherOptions'];

export const fetchRingListControllerGenerateRingListByDateExport = (
  variables: RingListControllerGenerateRingListByDateExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    RingListControllerGenerateRingListByDateExportError,
    Schemas.GenerageRingListByDateExportDto,
    {},
    {},
    {}
  >({
    url: '/production-and-performance/ring-list/export-per-day',
    method: 'post',
    ...variables,
    signal,
  });

export const useRingListControllerGenerateRingListByDateExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      RingListControllerGenerateRingListByDateExportError,
      RingListControllerGenerateRingListByDateExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    RingListControllerGenerateRingListByDateExportError,
    RingListControllerGenerateRingListByDateExportVariables
  >({
    mutationFn: (
      variables: RingListControllerGenerateRingListByDateExportVariables,
    ) =>
      fetchRingListControllerGenerateRingListByDateExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RingListControllerGenerateRingListByRingExportError =
  Fetcher.ErrorWrapper<undefined>;

export type RingListControllerGenerateRingListByRingExportVariables = {
  body: Schemas.GenerageRingListByRingExportDto;
} & ApiContext['fetcherOptions'];

export const fetchRingListControllerGenerateRingListByRingExport = (
  variables: RingListControllerGenerateRingListByRingExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    RingListControllerGenerateRingListByRingExportError,
    Schemas.GenerageRingListByRingExportDto,
    {},
    {},
    {}
  >({
    url: '/production-and-performance/ring-list/export-per-ring',
    method: 'post',
    ...variables,
    signal,
  });

export const useRingListControllerGenerateRingListByRingExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      RingListControllerGenerateRingListByRingExportError,
      RingListControllerGenerateRingListByRingExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    RingListControllerGenerateRingListByRingExportError,
    RingListControllerGenerateRingListByRingExportVariables
  >({
    mutationFn: (
      variables: RingListControllerGenerateRingListByRingExportVariables,
    ) =>
      fetchRingListControllerGenerateRingListByRingExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DailyAdvancementControllerRingPerShiftQueryParams = {
  constructionSiteId: string;
  startDate: string;
  endDate: string;
};

export type DailyAdvancementControllerRingPerShiftError =
  Fetcher.ErrorWrapper<undefined>;

export type DailyAdvancementControllerRingPerShiftVariables = {
  queryParams: DailyAdvancementControllerRingPerShiftQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDailyAdvancementControllerRingPerShift = (
  variables: DailyAdvancementControllerRingPerShiftVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GetRingPerShiftResponseDto,
    DailyAdvancementControllerRingPerShiftError,
    undefined,
    {},
    DailyAdvancementControllerRingPerShiftQueryParams,
    {}
  >({
    url: '/production-and-performance/daily-advancement',
    method: 'get',
    ...variables,
    signal,
  });

export const useDailyAdvancementControllerRingPerShift = <
  TData = Schemas.GetRingPerShiftResponseDto,
>(
  variables: DailyAdvancementControllerRingPerShiftVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetRingPerShiftResponseDto,
      DailyAdvancementControllerRingPerShiftError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetRingPerShiftResponseDto,
    DailyAdvancementControllerRingPerShiftError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/production-and-performance/daily-advancement',
      operationId: 'dailyAdvancementControllerRingPerShift',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDailyAdvancementControllerRingPerShift(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PerformanceMetricsControllerGetPerformanceMetricsQueryParams = {
  constructionSiteId: string;
};

export type PerformanceMetricsControllerGetPerformanceMetricsError =
  Fetcher.ErrorWrapper<undefined>;

export type PerformanceMetricsControllerGetPerformanceMetricsVariables = {
  queryParams: PerformanceMetricsControllerGetPerformanceMetricsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPerformanceMetricsControllerGetPerformanceMetrics = (
  variables: PerformanceMetricsControllerGetPerformanceMetricsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GetPerformanceMetricsResponseDto,
    PerformanceMetricsControllerGetPerformanceMetricsError,
    undefined,
    {},
    PerformanceMetricsControllerGetPerformanceMetricsQueryParams,
    {}
  >({
    url: '/production-and-performance/performance-metrics',
    method: 'get',
    ...variables,
    signal,
  });

export const usePerformanceMetricsControllerGetPerformanceMetrics = <
  TData = Schemas.GetPerformanceMetricsResponseDto,
>(
  variables: PerformanceMetricsControllerGetPerformanceMetricsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetPerformanceMetricsResponseDto,
      PerformanceMetricsControllerGetPerformanceMetricsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetPerformanceMetricsResponseDto,
    PerformanceMetricsControllerGetPerformanceMetricsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/production-and-performance/performance-metrics',
      operationId: 'performanceMetricsControllerGetPerformanceMetrics',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchPerformanceMetricsControllerGetPerformanceMetrics(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProgressHistoryControllerProgressHistoryQueryParams = {
  constructionSiteId: string;
  startDate: string;
  endDate: string;
};

export type ProgressHistoryControllerProgressHistoryError =
  Fetcher.ErrorWrapper<undefined>;

export type ProgressHistoryControllerProgressHistoryVariables = {
  queryParams: ProgressHistoryControllerProgressHistoryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProgressHistoryControllerProgressHistory = (
  variables: ProgressHistoryControllerProgressHistoryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GetProgressHistoryResponseDto,
    ProgressHistoryControllerProgressHistoryError,
    undefined,
    {},
    ProgressHistoryControllerProgressHistoryQueryParams,
    {}
  >({
    url: '/production-and-performance/progress-history',
    method: 'get',
    ...variables,
    signal,
  });

export const useProgressHistoryControllerProgressHistory = <
  TData = Schemas.GetProgressHistoryResponseDto,
>(
  variables: ProgressHistoryControllerProgressHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetProgressHistoryResponseDto,
      ProgressHistoryControllerProgressHistoryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetProgressHistoryResponseDto,
    ProgressHistoryControllerProgressHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/production-and-performance/progress-history',
      operationId: 'progressHistoryControllerProgressHistory',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProgressHistoryControllerProgressHistory(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type RingPerDayControllerRingPerDayQueryParams = {
  constructionSiteId: string;
  startDate?: string;
  endDate?: string;
  dayMode: 'shift' | 'calendar';
  startRing?: number;
  endRing?: number;
};

export type RingPerDayControllerRingPerDayError =
  Fetcher.ErrorWrapper<undefined>;

export type RingPerDayControllerRingPerDayVariables = {
  queryParams: RingPerDayControllerRingPerDayQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRingPerDayControllerRingPerDay = (
  variables: RingPerDayControllerRingPerDayVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    | (Omit<Schemas.GetCalendarRingPerDayResponseDto, 'dayMode'> & {
        dayMode: 'calendar';
      })
    | (Omit<Schemas.GetShiftRingPerDayResponseDto, 'dayMode'> & {
        dayMode: 'shift';
      }),
    RingPerDayControllerRingPerDayError,
    undefined,
    {},
    RingPerDayControllerRingPerDayQueryParams,
    {}
  >({
    url: '/production-and-performance/ring-per-day',
    method: 'get',
    ...variables,
    signal,
  });

export const useRingPerDayControllerRingPerDay = <
  TData =
    | (Omit<Schemas.GetCalendarRingPerDayResponseDto, 'dayMode'> & {
        dayMode: 'calendar';
      })
    | (Omit<Schemas.GetShiftRingPerDayResponseDto, 'dayMode'> & {
        dayMode: 'shift';
      }),
>(
  variables: RingPerDayControllerRingPerDayVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      | (Omit<Schemas.GetCalendarRingPerDayResponseDto, 'dayMode'> & {
          dayMode: 'calendar';
        })
      | (Omit<Schemas.GetShiftRingPerDayResponseDto, 'dayMode'> & {
          dayMode: 'shift';
        }),
      RingPerDayControllerRingPerDayError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    | (Omit<Schemas.GetCalendarRingPerDayResponseDto, 'dayMode'> & {
        dayMode: 'calendar';
      })
    | (Omit<Schemas.GetShiftRingPerDayResponseDto, 'dayMode'> & {
        dayMode: 'shift';
      }),
    RingPerDayControllerRingPerDayError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/production-and-performance/ring-per-day',
      operationId: 'ringPerDayControllerRingPerDay',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRingPerDayControllerRingPerDay(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type RingPerDayControllerGenerateRingPerDayExportError =
  Fetcher.ErrorWrapper<undefined>;

export type RingPerDayControllerGenerateRingPerDayExportVariables = {
  body: Schemas.RingPerDayExportInputDto;
} & ApiContext['fetcherOptions'];

export const fetchRingPerDayControllerGenerateRingPerDayExport = (
  variables: RingPerDayControllerGenerateRingPerDayExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    RingPerDayControllerGenerateRingPerDayExportError,
    Schemas.RingPerDayExportInputDto,
    {},
    {},
    {}
  >({
    url: '/production-and-performance/ring-per-day/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useRingPerDayControllerGenerateRingPerDayExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      RingPerDayControllerGenerateRingPerDayExportError,
      RingPerDayControllerGenerateRingPerDayExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    RingPerDayControllerGenerateRingPerDayExportError,
    RingPerDayControllerGenerateRingPerDayExportVariables
  >({
    mutationFn: (
      variables: RingPerDayControllerGenerateRingPerDayExportVariables,
    ) =>
      fetchRingPerDayControllerGenerateRingPerDayExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationQueryParams =
  {
    constructionSiteId: string;
    startDate?: string;
    endDate?: string;
    dayMode: 'shift' | 'calendar';
    startRing?: number;
    endRing?: number;
  };

export type ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError =
  Fetcher.ErrorWrapper<undefined>;

export type ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables =
  {
    queryParams: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationQueryParams;
  } & ApiContext['fetcherOptions'];

export const fetchExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration =
  (
    variables: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables,
    signal?: AbortSignal,
  ) =>
    apiFetch<
      Schemas.GetExcavationAndBuildingDurationResponseDto,
      ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError,
      undefined,
      {},
      ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationQueryParams,
      {}
    >({
      url: '/production-and-performance/excavation-and-building-duration',
      method: 'get',
      ...variables,
      signal,
    });

export const useExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration =
  <TData = Schemas.GetExcavationAndBuildingDurationResponseDto,>(
    variables: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables,
    options?: Omit<
      reactQuery.UseQueryOptions<
        Schemas.GetExcavationAndBuildingDurationResponseDto,
        ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError,
        TData
      >,
      'queryKey' | 'queryFn' | 'initialData'
    >,
  ) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<
      Schemas.GetExcavationAndBuildingDurationResponseDto,
      ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError,
      TData
    >({
      queryKey: queryKeyFn({
        path: '/production-and-performance/excavation-and-building-duration',
        operationId:
          'excavationAndBuildingDurationControllerGetExcavationAndBuildingDuration',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    });
  };

export type ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError =
  Fetcher.ErrorWrapper<undefined>;

export type ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables =
  {
    body: Schemas.ExcavationAndBuildingDurationExportInputDto;
  } & ApiContext['fetcherOptions'];

export const fetchExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport =
  (
    variables: ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables,
    signal?: AbortSignal,
  ) =>
    apiFetch<
      Schemas.ExcavationAndBuildingDurationExportResponseDto,
      ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError,
      Schemas.ExcavationAndBuildingDurationExportInputDto,
      {},
      {},
      {}
    >({
      url: '/production-and-performance/excavation-and-building-duration/export',
      method: 'post',
      ...variables,
      signal,
    });

export const useExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport =
  (
    options?: Omit<
      reactQuery.UseMutationOptions<
        Schemas.ExcavationAndBuildingDurationExportResponseDto,
        ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError,
        ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables
      >,
      'mutationFn'
    >,
  ) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<
      Schemas.ExcavationAndBuildingDurationExportResponseDto,
      ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError,
      ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables
    >({
      mutationFn: (
        variables: ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables,
      ) =>
        fetchExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport(
          { ...fetcherOptions, ...variables },
        ),
      ...options,
    });
  };

export type PlanningControllerImportPlanningError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: {
    /**
     * @example 400
     */
    statusCode: number;
    /**
     * @example Validation failed: invalid planning file
     */
    message: string;
    /**
     * @example INVALID_PLANNING_FILE
     */
    error?: string;
  };
}>;

export type PlanningControllerImportPlanningRequestBody = {
  constructionSiteId: string;
  /**
   * @format binary
   */
  file: Blob;
};

export type PlanningControllerImportPlanningVariables = {
  body: PlanningControllerImportPlanningRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchPlanningControllerImportPlanning = (
  variables: PlanningControllerImportPlanningVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PlanningControllerImportPlanningError,
    PlanningControllerImportPlanningRequestBody,
    {},
    {},
    {}
  >({
    url: '/production-and-performance/planning/import',
    method: 'post',
    ...variables,
    signal,
  });

export const usePlanningControllerImportPlanning = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PlanningControllerImportPlanningError,
      PlanningControllerImportPlanningVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PlanningControllerImportPlanningError,
    PlanningControllerImportPlanningVariables
  >({
    mutationFn: (variables: PlanningControllerImportPlanningVariables) =>
      fetchPlanningControllerImportPlanning({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PlanningControllerGeneratePlanningExportError =
  Fetcher.ErrorWrapper<undefined>;

export type PlanningControllerGeneratePlanningExportVariables = {
  body: Schemas.PlanningExportInputDto;
} & ApiContext['fetcherOptions'];

export const fetchPlanningControllerGeneratePlanningExport = (
  variables: PlanningControllerGeneratePlanningExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    PlanningControllerGeneratePlanningExportError,
    Schemas.PlanningExportInputDto,
    {},
    {},
    {}
  >({
    url: '/production-and-performance/planning/export',
    method: 'post',
    ...variables,
    signal,
  });

export const usePlanningControllerGeneratePlanningExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      PlanningControllerGeneratePlanningExportError,
      PlanningControllerGeneratePlanningExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    PlanningControllerGeneratePlanningExportError,
    PlanningControllerGeneratePlanningExportVariables
  >({
    mutationFn: (
      variables: PlanningControllerGeneratePlanningExportVariables,
    ) =>
      fetchPlanningControllerGeneratePlanningExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PlanningControllerGetPlanningQueryParams = {
  constructionSiteId: string;
  startDate?: string | null;
  endDate?: string | null;
  startRing?: number | null;
  endRing?: number | null;
  withProjection?: boolean | null;
};

export type PlanningControllerGetPlanningError =
  Fetcher.ErrorWrapper<undefined>;

export type PlanningControllerGetPlanningVariables = {
  queryParams: PlanningControllerGetPlanningQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPlanningControllerGetPlanning = (
  variables: PlanningControllerGetPlanningVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GetPlanningResponseDto,
    PlanningControllerGetPlanningError,
    undefined,
    {},
    PlanningControllerGetPlanningQueryParams,
    {}
  >({
    url: '/production-and-performance/planning',
    method: 'get',
    ...variables,
    signal,
  });

export const usePlanningControllerGetPlanning = <
  TData = Schemas.GetPlanningResponseDto,
>(
  variables: PlanningControllerGetPlanningVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetPlanningResponseDto,
      PlanningControllerGetPlanningError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetPlanningResponseDto,
    PlanningControllerGetPlanningError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/production-and-performance/planning',
      operationId: 'planningControllerGetPlanning',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchPlanningControllerGetPlanning(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/production-and-performance/daily-advancement';
      operationId: 'dailyAdvancementControllerRingPerShift';
      variables: DailyAdvancementControllerRingPerShiftVariables;
    }
  | {
      path: '/production-and-performance/performance-metrics';
      operationId: 'performanceMetricsControllerGetPerformanceMetrics';
      variables: PerformanceMetricsControllerGetPerformanceMetricsVariables;
    }
  | {
      path: '/production-and-performance/progress-history';
      operationId: 'progressHistoryControllerProgressHistory';
      variables: ProgressHistoryControllerProgressHistoryVariables;
    }
  | {
      path: '/production-and-performance/ring-per-day';
      operationId: 'ringPerDayControllerRingPerDay';
      variables: RingPerDayControllerRingPerDayVariables;
    }
  | {
      path: '/production-and-performance/excavation-and-building-duration';
      operationId: 'excavationAndBuildingDurationControllerGetExcavationAndBuildingDuration';
      variables: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables;
    }
  | {
      path: '/production-and-performance/planning';
      operationId: 'planningControllerGetPlanning';
      variables: PlanningControllerGetPlanningVariables;
    };
