import Icon from '@ant-design/icons/lib/components/Icon';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { Flex } from 'antd';

import { formatPointToMapPoint } from 'business/dashboard/components/map/services';
import { Point } from 'business/dashboard/components/map/types';
import { CustomIconProps } from 'ui/icons/types';

import styles from './index.module.scss';

interface TbmMarkerProps {
  angle: number;
}

interface TbmPositionProps {
  lastPoint: Point;
}

const ArrowSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L19 21L12 17L5 21L12 2Z"
        stroke="#EEF9E8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowIcon = (props: CustomIconProps) => {
  return <Icon component={ArrowSvg} {...props} />;
};

export const TbmMarker = ({ angle }: TbmMarkerProps) => {
  return (
    <Flex className={styles.container} align="center" justify="center">
      <ArrowIcon
        style={{
          // Rotation in CSS:
          // - 0 deg is equivalent to pointing top -> add `Pi/2` to be at the 0 of the trigonometric circle
          // - angle rotation is clockwise in CSS -> `-angle` to have an anti-clockwise rotation
          transform: `rotate(${-angle + Math.PI / 2}rad)`,
        }}
      />
    </Flex>
  );
};

export const TbmPosition = ({
  lastPoint,
  angle,
}: TbmPositionProps & TbmMarkerProps) => {
  const markerPoint = formatPointToMapPoint(lastPoint);

  return (
    <AdvancedMarker position={markerPoint} anchorPoint={['50%', '50%']}>
      <TbmMarker angle={angle} />
    </AdvancedMarker>
  );
};
