import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import {
  X_SCROLL_BASE_TRIGGER_VALUE,
  Y_SCROLL_TRIGGER_VALUE,
} from 'business/data-analysis/pages/graph/components/graph-table-view/constants';
import { useGraphSet } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { isRingParameter } from 'business/data-analysis/pages/graph/hooks/graph-context/is-ring-parameter';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';

import { useGraph } from './../../hooks/use-graph';

interface Props {
  graphId: string;
}

const useFirstColumn = (mode: PlotterMode): ColumnType<any> => {
  const { t } = useTranslation();
  if (mode === PlotterMode.TIME_PERIOD) {
    return {
      title: t('dataAnalysis.timePeriodMode.date'),
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      width: 200,
    };
  }

  return {
    title: t('dataAnalysis.ringMode.ring'),
    dataIndex: 'ring',
    key: 'ring',
    fixed: 'left',
    width: 200,
  };
};

const GraphTableView = ({ graphId }: Props) => {
  const mode = useMode();
  const { parameters } = useGraph(graphId);
  const graphSet = useGraphSet();

  const firstColumn = useFirstColumn(mode);
  const params = Array.from(parameters.values());

  const columns: TableProps['columns'] = [
    firstColumn,
    ...params.map((param) => {
      const key = isRingParameter(graphSet, param)
        ? `${param.id}_${param.aggregationType}_${param.computeType}`
        : param.id;

      return {
        title: param.name,
        dataIndex: key,
        key,
      };
    }),
  ];

  const xScroll = parameters.size * X_SCROLL_BASE_TRIGGER_VALUE;

  return (
    <Table
      columns={columns}
      dataSource={graphSet.values}
      virtual
      scroll={{ x: xScroll, y: Y_SCROLL_TRIGGER_VALUE }}
      pagination={false}
      bordered
      size="small"
    />
  );
};

export default GraphTableView;
