import { Typography } from 'antd';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useCheckPermission } from 'business/contextProviders/usePermissions';
import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props {
  route: Routes;
  title: string;
  icon: ReactNode;
  description: string;
  permissions: Module_Enum[];
  onClick: () => void;
  withDescription?: boolean;
}

const MenuEntry = ({
  route,
  title,
  icon,
  description,
  permissions,
  onClick,
  withDescription = true,
}: Props) => {
  const permissionCheck = useCheckPermission(permissions);
  if (!permissionCheck) {
    return null;
  }

  return (
    <Link to={route} className={styles.button} onClick={onClick}>
      <span className={styles.icon}>{icon}</span>
      <Flex column className={styles.content}>
        <Typography className={styles.title}>{title}</Typography>
        {withDescription ? (
          <Typography className={styles.description}>{description}</Typography>
        ) : null}
      </Flex>
    </Link>
  );
};

export default MenuEntry;
