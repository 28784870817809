import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import GraphCardHeader from 'business/data-analysis/pages/graph/components/graph-card-header';
import GraphSection from 'business/data-analysis/pages/graph/components/graph-section';
import ParameterList from 'business/data-analysis/pages/graph/components/parameter-list';
import { useGraphSetDispatch } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';
import Flex from 'ui/flex';
import TrashIcon from 'ui/icons/trash';
import { SimpleCard } from 'ui/SimpleCard';

import styles from './index.module.scss';

interface Props {
  graphId: string;
}

export const GraphCard = ({ graphId }: Props) => {
  const { t } = useTranslation();

  const graph = useGraph(graphId);
  const dispatch = useGraphSetDispatch();
  const [displayParameterList, setDisplayParameterList] = useState(true);

  const openParameterSidebar = () => {
    dispatch({ type: GraphSetDispatchActions.SetCurrentGraphId, graphId });
  };

  const removeAllParameters = () => {
    dispatch({
      type: GraphSetDispatchActions.RemoveParameters,
      graphId,
      parameterUniqueIds: Array.from(graph.parameters.keys()),
    });
  };

  return (
    <SimpleCard className={styles.graphCard}>
      <GraphCardHeader
        graphId={graphId}
        displayParameterList={displayParameterList}
        toogleDisplayParameterList={() =>
          setDisplayParameterList(!displayParameterList)
        }
      />

      <Flex className={styles.graphSectionContainer}>
        {displayParameterList ? (
          <div className={styles.parameterSection}>
            <Button
              className={styles.addParameter}
              type="primary"
              onClick={openParameterSidebar}
              icon={<PlusOutlined />}
              iconPosition="end"
            >
              {t('dataAnalysis.actions.addParameter')}
            </Button>
            {graph.parameters.size > 0 ? (
              <Button
                icon={<TrashIcon className={styles.removeAllParametersIcon} />}
                iconPosition="end"
                className={styles.removeAllParameters}
                onClick={removeAllParameters}
              >
                {t('dataAnalysis.actions.removeParameters')}
              </Button>
            ) : null}
            <ParameterList graphId={graphId} />
          </div>
        ) : null}

        <GraphSection graphId={graphId} />
      </Flex>
    </SimpleCard>
  );
};
