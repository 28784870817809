import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import { useTranslation } from 'translations/hooks';

export function ShiftOrCalendarSelector(props: {
  value?: 'shift' | 'calendar';
  onChange?: (e: RadioChangeEvent) => void;
}) {
  const { t } = useTranslation();

  const options = [
    { label: t('time.shiftOrCalendarSelector.shift'), value: 'shift' },
    {
      label: t('time.shiftOrCalendarSelector.calendar'),
      value: 'calendar',
    },
  ];

  return (
    <Radio.Group
      options={options}
      onChange={props.onChange}
      value={props.value}
      optionType="button"
      buttonStyle="solid"
    />
  );
}
