import { useMemo } from 'react';

import { GetTbmInfosQueryResult } from 'generated/graphql';

export const useData = (data: GetTbmInfosQueryResult['data']) => {
  return useMemo(() => {
    const constructionSite = data?.constructionSite.at(0);
    if (!constructionSite) {
      return null;
    }

    const currentRingNumber = constructionSite?.rings.at(0)?.ringNumber ?? 0;
    const totalRings = constructionSite?.nbTotalRings ?? null;

    const currentMetricPoint =
      constructionSite.rings.at(0)?.buildEndMetricPoint ?? 0;
    const totalMeters = constructionSite.nbTotalMeters ?? null;

    const advancement = totalMeters
      ? (currentMetricPoint / totalMeters) * 100
      : 0;
    let percent = advancement;
    if (advancement < 0) {
      percent = 0;
    }
    if (advancement > 100) {
      percent = 100;
    }

    const roundedPercent = +percent.toFixed(2);

    return {
      percent: roundedPercent,
      currentRingNumber,
      totalRings,
      currentMetricPoint,
      totalMeters,
    };
  }, [data?.constructionSite]);
};
