import { GraphPageState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { GetGraphSetQuery } from 'generated/graphql';

export const mapToGraphSetFromStructureDto = (
  loadedGraphSetStructure: NonNullable<
    Required<GetGraphSetQuery>['graphSet_by_pk']
  >,
) => {
  return {
    id: loadedGraphSetStructure.id,
    title: loadedGraphSetStructure.title,
    type: loadedGraphSetStructure.type,
    graphs: new Map(
      // Typed explicitely as any because of the generated type is any
      loadedGraphSetStructure.graphs.map(({ id: graphId, graph }: any) => {
        return [
          graphId,
          {
            ...graph,
            parameters: new Map(
              // Typed explicitely as any because of the generated type is any
              graph.parameters.map(({ id: paramId, parameter }: any) => {
                return [paramId, parameter];
              }),
            ),
          },
        ];
      }),
    ),
  } satisfies GraphPageState['structure'];
};
