import { v4 as uuidV4 } from 'uuid';

import {
  mapPlotterModeToGraphSetType,
  PlotterMode,
} from 'business/data-analysis/constants';
import { DisplayType } from 'business/data-analysis/pages/graph/types';

import { GraphPageState } from './../types';

export const resetGraphSet = (mode: PlotterMode): GraphPageState => {
  return {
    currentGraphId: null,
    isLoading: false,
    values: [],
    structure: {
      title: '',
      type: mapPlotterModeToGraphSetType[mode],
      graphs: new Map().set(uuidV4(), {
        title: 'Graph 1',
        displayType: DisplayType.graph,
        parameters: new Map(),
      }),
    },
  };
};
