import { GraphPageState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { GraphSetStructureDto } from 'generated/apiSchemas';

export const mapToStructureDtoFromGraphSetStructure = (
  structure: GraphPageState['structure'],
) => {
  return {
    ...structure,
    graphs: Array.from(structure.graphs.entries()).map(([graphId, graph]) => {
      const parameters = Array.from(graph.parameters.entries()).map(
        ([paramId, param]) => {
          return {
            id: paramId,
            parameter: param,
          };
        },
      );

      return {
        id: graphId,
        graph: {
          ...graph,
          parameters,
        },
      };
    }),
  } satisfies GraphSetStructureDto;
};
