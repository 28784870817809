import { Flex } from 'antd';
import { PropsWithChildren } from 'react';

import { useCheckPermission } from 'business/contextProviders/usePermissions';

import styles from './index.module.scss';

interface Props {
  permissions: string[];
}

const MenuColumn = ({ children, permissions }: PropsWithChildren<Props>) => {
  const permissionCheck = useCheckPermission(permissions);
  if (!permissionCheck) {
    return null;
  }
  return <Flex className={styles.container}>{children}</Flex>;
};

export default MenuColumn;
