import { Empty, Result } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ConstructionSitePlanningGraph } from 'business/production-and-performance/components/construction-site-planning-graph';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import Loader from 'ui/loader';

import { useGetFilteredRingsRealProgressionQuery } from './hook/use-get-filtered-and-paginated-rings-query';

export const ConstructionSitePlanningContainer = () => {
  const { t } = useTranslation();

  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  invariant(constructionSiteId, 'No constructionSite id');

  const filters = useProdAndPerfFilters();

  const { filteredData, loading, error, messageContextHolder } =
    useGetFilteredRingsRealProgressionQuery(constructionSiteId, filters);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Result status="warning" title={t('errors.error_generic')} />;
  }

  if (!filteredData || !filteredData.length) {
    return <Empty />;
  }

  return (
    <>
      {messageContextHolder}
      <ConstructionSitePlanningGraph data={filteredData} />
    </>
  );
};
