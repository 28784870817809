import invariant from 'tiny-invariant';

import { isRingParameter } from './../is-ring-parameter';
import { GraphPageState } from './../types';

export const updateParameter = (
  graphSet: GraphPageState,
  graphId: string,
  parameterUniqueId: string,
  update: { color?: string; aggregationType?: string; computeType?: string },
): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const graphToModify = graphs.get(graphId);
  invariant(
    graphToModify,
    'Trying to update a parameter of a non-existing graph',
  );

  const parameter = graphToModify.parameters.get(parameterUniqueId);
  invariant(parameter, 'Trying to update a non-existing parameter');

  const ringParameter = isRingParameter(graphSet, parameter)
    ? {
        aggregationType: update.aggregationType ?? parameter.aggregationType,
        computeType: update.computeType ?? parameter.computeType,
      }
    : {};

  const updatedParameter = {
    ...parameter,
    color: update.color ?? parameter.color,
    ...ringParameter,
  };

  graphToModify.parameters.set(parameterUniqueId, updatedParameter);
  graphs.set(graphId, graphToModify);

  return {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  };
};
