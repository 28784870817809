import { TFunction } from 'translations/hooks';

import { createEmptyGraph } from './../graph-set-values';
import { GraphPageState } from './../types';

export const addGraph = (
  graphSet: GraphPageState,
  t: TFunction,
): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const { id, newGraph } = createEmptyGraph(t);
  graphs.set(id, newGraph);

  const res = {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  } satisfies GraphPageState;

  return res;
};
