import { RangeFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider/types';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

export function getFetchRange(
  filters: RangeFilters,
):
  | { startDate: string; endDate: string }
  | { startRing: number; endRing: number }
  | null {
  if (filters.filterType === 'ring' && filters.ringRange) {
    const [startRing, endRing] = filters.ringRange;

    if (!startRing || !endRing) {
      return null;
    }
    return {
      startRing: startRing,
      endRing: endRing,
    };
  } else if (filters.filterType === 'date' && filters.dateRange) {
    const [startDate, endDate] = filters.dateRange;

    if (!startDate || !endDate) {
      return null;
    }
    return {
      startDate: startDate.format(SIMPLE_DATE_FORMAT),
      endDate: endDate.format(SIMPLE_DATE_FORMAT),
    };
  }

  return null;
}
