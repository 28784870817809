import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { FilterCard } from 'ui/filter-card';

import { RingPerDayGraphContainer } from './components/ring-per-day-graph-container';
import { RingPerDayHeader } from './components/ring-per-day-header';

const RingPerDayPage = () => {
  const { t } = useTranslation();

  return (
    <ProdAndPerfFiltersProvider
      initialFilters={{
        filterType: 'date',
        dateRange: [dayjs().subtract(1, 'week'), dayjs()],
        dayMode: 'shift',
      }}
    >
      <ProdPerfPageTemplate
        title={t('productionAndPerformance.ringPerDay.title')}
        header={<RingPerDayHeader />}
        filters={
          <FilterCard>
            <ProdAndPerfFilters dayModeFilterEnabled />
          </FilterCard>
        }
      >
        <RingPerDayGraphContainer />
      </ProdPerfPageTemplate>
    </ProdAndPerfFiltersProvider>
  );
};

export default RingPerDayPage;
