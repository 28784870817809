import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  Button,
  Card,
  ColorPicker,
  ConfigProvider,
  Form,
  Select,
  Tag,
  Typography,
} from 'antd';
import { Color } from 'antd/es/color-picker';
import { TFunction, useTranslation } from 'translations/hooks';

import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { isRingParameter } from 'business/data-analysis/pages/graph/hooks/graph-context/is-ring-parameter';
import {
  GraphSetDispatchActions,
  RingParameter,
} from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraphParameter } from 'business/data-analysis/pages/graph/hooks/use-graph-parameter';
import Flex from 'ui/flex';
import TrashIcon from 'ui/icons/trash';

import styles from './index.module.scss';

interface Props {
  graphId: string;
  parameterUniqueId: string;
}

const computeTypeOptions = (t: TFunction) => [
  {
    value: 'excavation',
    label: t('dataAnalysis.ringMode.computeType.excavation'),
  },
  {
    value: 'build',
    label: t('dataAnalysis.ringMode.computeType.build'),
  },
  {
    value: 'excavation_and_build',
    label: t('dataAnalysis.ringMode.computeType.excavation_and_build'),
  },
  {
    value: 'total',
    label: t('dataAnalysis.ringMode.computeType.total'),
  },
];

const aggregationTypeOptions = (t: TFunction) => [
  {
    value: 'avg',
    label: t('dataAnalysis.ringMode.aggregationType.avg'),
  },
  {
    value: 'max',
    label: t('dataAnalysis.ringMode.aggregationType.max'),
  },
  {
    value: 'min',
    label: t('dataAnalysis.ringMode.aggregationType.min'),
  },
  {
    value: 'last',
    label: t('dataAnalysis.ringMode.aggregationType.last'),
  },
];

const ParameterCard = ({ graphId, parameterUniqueId }: Props) => {
  const { t } = useTranslation();

  const graphSet = useGraphSet();
  const parameter = useGraphParameter(graphId, parameterUniqueId);

  const dispatch = useGraphSetDispatch();

  const changeColor = (color: Color) => {
    dispatch({
      type: GraphSetDispatchActions.UpdateParameter,
      graphId,
      parameterUniqueId,
      update: { color: color.toHexString() },
    });
  };

  const changeComputeType = (computeType: RingParameter['computeType']) => {
    dispatch({
      type: GraphSetDispatchActions.UpdateParameter,
      graphId,
      parameterUniqueId,
      update: { computeType },
    });
  };

  const changeAggregationType = (
    aggregationType: RingParameter['aggregationType'],
  ) => {
    dispatch({
      type: GraphSetDispatchActions.UpdateParameter,
      graphId,
      parameterUniqueId,
      update: { aggregationType },
    });
  };

  return (
    <Card className={styles.parameterCard}>
      <Flex column>
        <Flex justify="space-between" className={styles.cardContent}>
          <Flex alignItems="center" className={styles.parameterDefinition}>
            <ColorPicker
              size="small"
              onChangeComplete={changeColor}
              disabledAlpha
              value={parameter.color}
            />
            <Flex
              column
              justify="space-between"
              className={styles.parameterNameAndUnit}
            >
              <Typography>{parameter.name}</Typography>
              <Tag className={styles.unit}>{parameter.unit}</Tag>
            </Flex>
          </Flex>
          <Button
            className={styles.removeParameter}
            icon={<TrashIcon className={styles.removeParameterIcon} />}
            onClick={() =>
              dispatch({
                type: GraphSetDispatchActions.RemoveParameters,
                graphId,
                parameterUniqueIds: [parameterUniqueId],
              })
            }
          />
        </Flex>
        {isRingParameter(graphSet, parameter) && (
          <ConfigProvider
            // Override default margin (24px) on Form.Item
            theme={{ components: { Form: { itemMarginBottom: 5 } } }}
          >
            <Form.Item
              label={t('dataAnalysis.ringMode.computeType.title')}
              tooltip={{
                title: t('dataAnalysis.ringMode.computeType.tooltip'),
                icon: <InfoCircleTwoTone />,
              }}
            >
              <Select
                size="small"
                value={parameter.computeType}
                onChange={changeComputeType}
                options={computeTypeOptions(t)}
              />
            </Form.Item>

            <Form.Item
              label={t('dataAnalysis.ringMode.aggregationType.title')}
              tooltip={{
                title: t('dataAnalysis.ringMode.aggregationType.tooltip'),
                icon: <InfoCircleTwoTone />,
              }}
            >
              <Select
                size="small"
                value={parameter.aggregationType}
                onChange={changeAggregationType}
                options={aggregationTypeOptions(t)}
              />
            </Form.Item>
          </ConfigProvider>
        )}
      </Flex>
    </Card>
  );
};

export default ParameterCard;
