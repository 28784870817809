import { Row, Col, Statistic } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { parseAndFormatRingDuration } from 'business/production-and-performance/services/ring-duration';
import { GetExcavationAndBuildingDurationResponseDto } from 'generated/apiSchemas';

import styles from './index.module.scss';

interface Props {
  data: GetExcavationAndBuildingDurationResponseDto['averages'];
}

export const ExcavationBuildTimeAverages = ({ data }: Props) => {
  const { t } = useTranslation();

  const filters = useProdAndPerfFilters();
  const showTotalDurations = filters?.showTotalDurations;

  return (
    <Row className={styles.row} gutter={8}>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.excavationBuildTime.averages.avgRealExcavationDuration',
          )}
          value={parseAndFormatRingDuration(data.avgRealExcavationDuration)}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.excavationBuildTime.averages.avgRealBuildDuration',
          )}
          value={parseAndFormatRingDuration(data.avgRealBuildDuration)}
        />
      </Col>
      {showTotalDurations ? (
        <>
          <Col span={6}>
            <Statistic
              title={t(
                'productionAndPerformance.excavationBuildTime.averages.avgTotalExcavationDuration',
              )}
              value={parseAndFormatRingDuration(
                data.avgTotalExcavationDuration,
              )}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={t(
                'productionAndPerformance.excavationBuildTime.averages.avgTotalBuildDuration',
              )}
              value={parseAndFormatRingDuration(data.avgTotalBuildDuration)}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};
