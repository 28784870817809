import { Alert, Card, Col, Flex, Progress, Row, Typography } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useData } from 'business/dashboard/components/advancement-card/hooks/use-data';
import CardValue from 'business/dashboard/components/card-value';
import { GetTbmInfosQueryResult } from 'generated/graphql';
import Loader from 'ui/loader';

import styles from './index.module.scss';

interface AdvancementCardProps {
  tbmInfos: GetTbmInfosQueryResult;
}

export const AdvancementCard = ({
  tbmInfos: { data, error, loading },
}: AdvancementCardProps) => {
  const { t } = useTranslation();

  const extractedData = useData(data);

  if (error) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!extractedData) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  const {
    percent,
    currentRingNumber,
    totalRings,
    currentMetricPoint,
    totalMeters,
  } = extractedData;

  return (
    <Card className={styles.card}>
      <Flex vertical gap={10}>
        <Flex justify="space-between">
          <Typography>{t('dashboard.tbm.globalAdvancement.title')}</Typography>
          <Typography.Text strong>{percent}%</Typography.Text>
        </Flex>
        <Progress
          percent={percent}
          size={['100%', 20]}
          showInfo={false}
          className={styles.progress}
        />
        <Row gutter={[10, 10]}>
          <Col xs={24} lg={12}>
            <CardValue
              title={t('dashboard.tbm.globalAdvancement.ringNumber.title')}
              value={currentRingNumber}
              footer={t('dashboard.tbm.globalAdvancement.ringNumber.total', {
                totalRings: totalRings ?? '-',
              })}
            />
          </Col>
          <Col xs={24} lg={12}>
            <CardValue
              title={t('dashboard.tbm.globalAdvancement.metricPoint.title')}
              value={t('dashboard.tbm.globalAdvancement.metricPoint.value', {
                currentMetricPoint: currentMetricPoint.toFixed(2),
              })}
              footer={t('dashboard.tbm.globalAdvancement.metricPoint.total', {
                totalMeters: totalMeters ?? '-',
              })}
            />
          </Col>
        </Row>
      </Flex>
    </Card>
  );
};
