import invariant from 'tiny-invariant';

import { GraphPageState } from './../types';

export const renameGraph = (
  graphSet: GraphPageState,
  graphId: string,
  title: string,
): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const graphToModify = graphs.get(graphId);
  invariant(
    graphToModify,
    'Trying to update the title of a non-existing graph',
  );
  graphToModify.title = title;

  graphs.set(graphId, graphToModify);

  return { ...graphSet, structure: { ...graphSet.structure, graphs } };
};
