import { LoaderFunction, redirect } from 'react-router-dom';

import { PlotterMode } from 'business/data-analysis/constants';
import { GraphSetProvider } from 'business/data-analysis/pages/graph/hooks/graph-context/provider';
import { DataAnalysisGraph } from 'business/data-analysis/pages/graph/page';
import Routes from 'config/routes';

export const dataAnalysisGraphPageLoader = (({ params }) => {
  if (
    !params.mode ||
    !Object.values(PlotterMode).includes(params.mode as any)
  ) {
    return redirect(Routes.DataAnalysisModeSelection);
  }
  return { mode: params.mode as PlotterMode };
}) satisfies LoaderFunction;

export const DataAnalysisGraphPage = () => {
  return (
    <GraphSetProvider>
      <DataAnalysisGraph />
    </GraphSetProvider>
  );
};
