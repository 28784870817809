import dayjs from 'dayjs';

export const getAllDatesInRange = (startDate: Date, endDate: Date): Date[] => {
  return [startDate, endDate].reduce<Date[]>((acc, date) => {
    if (acc.length === 0) {
      return [date];
    }

    let last = acc[acc.length - 1];

    while (date > last) {
      acc.push(dayjs.tz(last).add(1, 'day').toDate());
      last = acc[acc.length - 1];
    }
    return acc;
  }, []);
};
