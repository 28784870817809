import { GraphPageState } from './../types';

export const deleteGraph = (
  graphSet: GraphPageState,
  graphId: string,
): GraphPageState => {
  const graphs = graphSet.structure.graphs;
  graphs.delete(graphId);
  return {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  };
};
