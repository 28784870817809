import { isRingParameter } from 'business/data-analysis/pages/graph/hooks/graph-context/is-ring-parameter';
import {
  DataAnalysisControllerGraphByPeriodVariables,
  DataAnalysisControllerGraphByRingVariables,
} from 'generated/apiComponents';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';

import { GraphFilters } from './../types';
import { GraphPageState, RingParameter } from './graph-context/types';

const periodQueryParams = (
  constructionSiteId: string,
  filters: GraphFilters,
  graphSet: GraphPageState,
) => {
  const graphs = Array.from(graphSet.structure.graphs.values());

  const parameterIds = Array.from(
    new Set(
      graphs.flatMap((graph) => {
        const parameters = Array.from(graph.parameters.values());

        return parameters.map((param) => {
          return param.id;
        });
      }),
    ),
  );

  return {
    body: {
      constructionSiteId,
      startDate: filters.dateFilters.startDate.toISOString(),
      endDate: filters.dateFilters.endDate.toISOString(),
      parameterIds,
    },
  };
};

const ringQueryParams = (
  constructionSiteId: string,
  filters: GraphFilters,
  graphSet: GraphPageState,
) => {
  const graphs = Array.from(graphSet.structure.graphs.values());

  const parametersWithAggregation = Array.from(
    new Set(
      graphs.flatMap((graph) => {
        const parameters = Array.from(graph.parameters.values());

        // The type is correctly filtered out but typescript does not know it
        const filteredParams = parameters.filter((param) =>
          isRingParameter(graphSet, param),
        ) as RingParameter[];

        return filteredParams.map((param) => {
          return {
            id: param.id,
            aggregationType: param.aggregationType,
            computeType: param.computeType,
          };
        });
      }),
    ),
  );

  return {
    body: {
      constructionSiteId,
      startRing: filters.ringFilters?.[0] ?? 0,
      endRing: filters.ringFilters?.[1] ?? 0,
      parametersWithAggregation,
    },
  };
};

export const useQueryParams = (
  constructionSiteId: string,
  filters: GraphFilters,
  graphSet: GraphPageState,
):
  | DataAnalysisControllerGraphByPeriodVariables
  | DataAnalysisControllerGraphByRingVariables => {
  if (graphSet.structure.type === GraphSetTypeEnum_Enum.Period) {
    return periodQueryParams(constructionSiteId, filters, graphSet);
  }

  return ringQueryParams(constructionSiteId, filters, graphSet);
};
