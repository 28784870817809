import { useMemo } from 'react';

import { formatPathToPolylinePath } from 'business/dashboard/components/map/services';
import { Point } from 'business/dashboard/components/map/types';
import { Polyline } from 'technical/map/polyline';

interface TraceProps {
  path: Point[];
  traceColor: string;
}

export const Trace = ({ path, traceColor }: TraceProps) => {
  const polylinePath = useMemo(() => {
    return formatPathToPolylinePath(path);
  }, [path]);

  return (
    <Polyline path={polylinePath} strokeWeight={6} strokeColor={traceColor} />
  );
};
