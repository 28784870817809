import { createContext, Dispatch, useContext } from 'react';

import { PlotterMode } from 'business/data-analysis/constants';
import { getInitialGraphSetState } from 'business/data-analysis/pages/graph/hooks/graph-context/graph-set-values';
import { GraphSetActionType } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';

export const PeriodGraphSetContext = createContext(
  getInitialGraphSetState(PlotterMode.TIME_PERIOD),
);

const RingGraphSetContext = createContext(
  getInitialGraphSetState(PlotterMode.RING),
);

export const getGraphSetContext = (mode: PlotterMode) => {
  if (mode === PlotterMode.TIME_PERIOD) {
    return PeriodGraphSetContext;
  }
  return RingGraphSetContext;
};

export const GraphSetUpdateContext = createContext<
  Dispatch<GraphSetActionType>
>(() => {});

export const useGraphSet = () => {
  const mode = useMode();

  const periodGraphSetContext = useContext(PeriodGraphSetContext);
  const ringGraphSetContext = useContext(RingGraphSetContext);

  if (mode === PlotterMode.TIME_PERIOD) {
    return periodGraphSetContext;
  }
  return ringGraphSetContext;
};

export const useGraphSetDispatch = () => {
  return useContext(GraphSetUpdateContext);
};
