import { Flex, Typography } from 'antd';
import { PropsWithChildren } from 'react';

import { useCheckPermission } from 'business/contextProviders/usePermissions';
import { Module_Enum } from 'generated/graphql';

import styles from './index.module.scss';

interface Props {
  title?: string;
  permissions: Module_Enum[];
}

const MenuSection = ({
  children,
  title,
  permissions,
}: PropsWithChildren<Props>) => {
  const permissionCheck = useCheckPermission(permissions);
  if (!permissionCheck) {
    return null;
  }

  return (
    <Flex vertical gap={'middle'}>
      {title ? <Typography className={styles.title}>{title}</Typography> : null}
      <Flex gap={'middle'} vertical>
        {children}
      </Flex>
    </Flex>
  );
};

export default MenuSection;
