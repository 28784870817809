import dayjs from 'dayjs';

export const formatGraphDateAxisLabel = (value: Date) => {
  // If interval is too small, Ag Chart display half day (12AM/12PM) label
  // By returning undefined, we skip the display
  if (value instanceof Date && dayjs(value).hour() != 0) {
    return undefined;
  }

  return dayjs(value).format('ll');
};
