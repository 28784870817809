import { GraphPageState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';

export const loadGraphSetStructure = (
  graphSet: GraphPageState,
  structure: GraphPageState['structure'],
  save: boolean = false,
): GraphPageState => {
  return {
    ...graphSet,
    currentGraphId: null,
    values: save ? graphSet.values : [],
    structure,
    savedState: structure,
  };
};
