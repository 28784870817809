import { TFunction } from 'translations/hooks';
import { v4 as uuidV4 } from 'uuid';

import { PlotterMode } from 'business/data-analysis/constants';
import { DisplayType } from 'business/data-analysis/pages/graph/types';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';

import { GraphPageState, PeriodParameter, RingParameter } from './types';

// TODO: add a translationKey to use if title is undefined

export const createEmptyGraph = (t: TFunction) => {
  return {
    id: uuidV4(),
    newGraph: {
      title: t('dataAnalysis.labels.newGraph', { graphNumber: 1 }),
      displayType: DisplayType.graph,
      parameters: new Map(),
    },
  };
};

const initialPeriodGraphSet: GraphPageState = {
  currentGraphId: null,
  isLoading: false,
  values: [],
  structure: {
    title: '',
    type: GraphSetTypeEnum_Enum.Period,
    graphs: new Map().set(uuidV4(), {
      title: 'Graph 1',
      displayType: DisplayType.graph,
      parameters: new Map<string, PeriodParameter>(),
    }),
  },
} satisfies GraphPageState;

const initialRingGraphSet: GraphPageState = {
  currentGraphId: null,
  isLoading: false,
  values: [],
  structure: {
    title: '',
    type: GraphSetTypeEnum_Enum.Ring,
    graphs: new Map().set(uuidV4(), {
      title: 'Graph 1',
      displayType: DisplayType.graph,
      parameters: new Map<string, RingParameter>(),
    }),
  },
} satisfies GraphPageState;

export const getInitialGraphSetState = (mode: PlotterMode) => {
  if (mode === PlotterMode.TIME_PERIOD) {
    return initialPeriodGraphSet;
  }
  return initialRingGraphSet;
};
