import classNames from 'classnames';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import ModeSelectionButton from 'business/data-analysis/pages/mode-selection/components/mode-selection-button';
import Routes from 'config/routes';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';
import CurveIcon from 'ui/icons/curve';
import WandIcon from 'ui/icons/wand';

import styles from './index.module.scss';

const DataAnalysisModeSelectionPage = () => {
  const { t } = useTranslation();

  const timePeriodDescription = [
    t('dataAnalysis.modeSelection.timePeriod.description.firstPart'),
    t('dataAnalysis.modeSelection.timePeriod.description.secondPart'),
  ];

  const ringDescription = [
    t('dataAnalysis.modeSelection.ring.description.firstPart'),
    t('dataAnalysis.modeSelection.ring.description.secondPart'),
    t('dataAnalysis.modeSelection.ring.description.thirdPart'),
  ];

  return (
    <div className={classNames(styles.page, 'page-appear')}>
      <CapCard
        title={t('dataAnalysis.modeSelection.title')}
        className={styles.card}
      >
        <Flex className={styles.container}>
          <ModeSelectionButton
            route={generatePath(Routes.DataAnalysisGraph, {
              mode: PlotterMode.TIME_PERIOD,
            })}
            icon={<CurveIcon />}
            title={t('dataAnalysis.modeSelection.timePeriod.title')}
            description={timePeriodDescription}
            variant="green"
          />
          <ModeSelectionButton
            route={generatePath(Routes.DataAnalysisGraph, {
              mode: PlotterMode.RING,
            })}
            icon={<WandIcon />}
            title={t('dataAnalysis.modeSelection.ring.title')}
            description={ringDescription}
          />
        </Flex>
      </CapCard>
    </div>
  );
};

export default DataAnalysisModeSelectionPage;
