import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

type ParseRingDurationResult = Duration | null | 'negative';

/**
 * Theses functions must be equivalent to the back function `formatRingDuration`
 * Except for the negative, which should be directly `null` in the back function
 * in back/custom-server/src/business/production-and-performance/production-and-performance-helper.service.ts
 * */
export const parseRingDuration = (
  durationString: string | null | undefined,
): ParseRingDurationResult => {
  if (isUndefinedOrNull(durationString)) {
    return null;
  }

  const duration = dayjs.duration(durationString);
  if (!dayjs.isDuration(duration)) {
    return null;
  }

  if (duration.asMilliseconds() < 0) {
    return 'negative';
  }

  return duration;
};

export const formatRingDuration = (duration: Duration) => {
  const hours = Math.trunc(duration.asHours()); // rm under hours values

  const minutes = duration.minutes().toString().padStart(2, '0');

  const formattedDuration = `${hours}:${minutes}`;

  return formattedDuration;
};

export const parseAndFormatRingDuration = (
  durationString: string | null | undefined,
) => {
  const duration = parseRingDuration(durationString);

  if (!duration || duration === 'negative') {
    return '-';
  }

  return formatRingDuration(duration);
};
