import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';

const { RangePicker: AntRangePicker } = DatePicker;

export const RangePicker = (
  props: Omit<RangePickerProps, 'showTime' | 'format'>,
) => {
  return (
    <AntRangePicker
      {...props}
      showTime={{
        format: 'HH:mm',
      }}
      format={'YYYY-MM-DD HH:mm'}
    />
  );
};
