import { message, TablePaginationConfig } from 'antd';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { RingsTable } from 'business/production-and-performance/pages/ring-table/components/ring-table';
import {
  PaginationProps,
  useGetFilteredAndPaginatedRingsQuery,
} from 'business/production-and-performance/pages/ring-table/components/ring-table-container/hooks/use-get-filtered-and-paginated-rings-query';
import { UpdateRingModal } from 'business/production-and-performance/pages/ring-table/components/update-ring-modal';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { GetRingsQuery } from 'generated/graphql';

const defaultCurrentPage = 1;
const defaultPageSize = 50;

export const RingsTableContainer: React.FC = () => {
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  const filters = useProdAndPerfFilters();
  invariant(constructionSiteId, 'No constructionSite id');

  const [pagination, setPagination] = useState<PaginationProps>({
    current: defaultCurrentPage,
    pageSize: defaultPageSize,
  });

  const [currentRecord, setCurrentRecord] = useState<
    GetRingsQuery['parameter_ring'][number] | null
  >(null);
  const isModalVisible = currentRecord !== null;

  const { data, loading, error, messageContextHolder } =
    useGetFilteredAndPaginatedRingsQuery(
      constructionSiteId,
      pagination,
      filters,
    );

  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      current: defaultCurrentPage,
      pageSize: defaultPageSize,
      ...newPagination,
    });
  };

  const openEditModal = (id: string | null) => {
    const ring = data?.parameter_ring.find((r) => r.id === id) || null;
    setCurrentRecord(ring);
  };

  const closeEditModal = () => {
    setCurrentRecord(null);
  };

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  return (
    <>
      {messageContextHolder}
      <RingsTable
        data={data?.parameter_ring}
        loading={loading}
        pagination={{
          ...pagination,
          total: data?.parameter_ring_aggregate?.aggregate?.count,
        }}
        onChangePagination={handlePaginationChange}
        onEditionClick={openEditModal}
      />
      <UpdateRingModal
        isModalVisible={isModalVisible}
        closeModal={closeEditModal}
        ring={currentRecord}
      />
    </>
  );
};
