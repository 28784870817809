import { ApolloQueryResult } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { GraphSetList } from 'business/data-analysis/pages/graph/components/graph-set-list';
import GraphSetSidebar from 'business/data-analysis/pages/graph/components/graph-set-side-bar';
import {
  Exact,
  GetSavedGraphCountQuery,
  GraphSetTypeEnum_Enum,
} from 'generated/graphql';

interface GraphListSideBarProps {
  visible: boolean;
  onClose: () => void;
  refetchGraphSetCount: (
    variables?:
      | Partial<
          Exact<{
            constructionSiteId: any;
            userId: any;
            type: GraphSetTypeEnum_Enum;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetSavedGraphCountQuery>>;
}

export const GraphListSidebar: React.FC<GraphListSideBarProps> = ({
  visible,
  onClose,
  refetchGraphSetCount,
}) => {
  const { t } = useTranslation();

  return (
    <GraphSetSidebar
      title={t('dataAnalysis.myGraphSets.title')}
      visible={visible}
      onClose={onClose}
    >
      {visible && (
        <GraphSetList
          onClose={onClose}
          refetchGraphSetCount={refetchGraphSetCount}
        />
      )}
    </GraphSetSidebar>
  );
};
