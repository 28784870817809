import { Typography, Select, Popconfirm, Button } from 'antd';
import { useTranslation } from 'translations/hooks';

import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';
import { DisplayType } from 'business/data-analysis/pages/graph/types';
import Flex from 'ui/flex';
import PencilIcon from 'ui/icons/pencil';
import TrashIcon from 'ui/icons/trash';

import styles from './index.module.scss';

interface Props {
  graphId: string;
  displayParameterList: boolean;
  toogleDisplayParameterList: () => void;
}

const GraphCardHeader = ({
  graphId,
  displayParameterList,
  toogleDisplayParameterList,
}: Props) => {
  const { t } = useTranslation();
  const graph = useGraph(graphId);
  const {
    structure: { graphs },
  } = useGraphSet();
  const dispatch = useGraphSetDispatch();

  const graphTypeOptions = [
    {
      value: DisplayType.graph,
      label: <span>{t('dataAnalysis.graphType.graph')}</span>,
    },
    {
      value: DisplayType.table,
      label: <span>{t('dataAnalysis.graphType.table')}</span>,
    },
  ];

  return (
    <Flex justify="space-between" className={styles.head}>
      <Flex>
        <Typography.Text
          className={styles.cardTitle}
          editable={{
            icon: <PencilIcon className={styles.cardTitleAction} />,
            tooltip: t('dataAnalysis.graph.header.tooltip'),
            onChange: (title: string) =>
              dispatch({
                type: GraphSetDispatchActions.RenameGraph,
                graphId,
                title,
              }),
            triggerType: ['icon'],
          }}
        >
          {graph.title}
        </Typography.Text>
      </Flex>

      <Flex className={styles.actionContainer}>
        <Button onClick={() => toogleDisplayParameterList()}>
          {t('dataAnalysis.graph.header.parameterListToogle', {
            context: displayParameterList ? 'hide' : 'show',
          })}
        </Button>
        <Select
          className={styles.graphType}
          options={graphTypeOptions}
          value={graph.displayType}
          onChange={(displayType) =>
            dispatch({
              type: GraphSetDispatchActions.UpdateDisplayType,
              graphId,
              displayType: displayType as DisplayType,
            })
          }
        />

        <Popconfirm
          title={t('dataAnalysis.graph.header.delete.title')}
          okButtonProps={{ type: 'primary' }}
          placement="topRight"
          onConfirm={() =>
            dispatch({
              type: GraphSetDispatchActions.DeleteGraph,
              graphId: graphId,
            })
          }
        >
          <Button
            icon={<TrashIcon className={styles.actionIcon} />}
            disabled={graphs.size <= 1}
          />
        </Popconfirm>
      </Flex>
    </Flex>
  );
};

export default GraphCardHeader;
