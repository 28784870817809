import { Point, PointWithMp } from './types';

export const formatPointToMapPoint = ({ latitude, longitude }: Point) => {
  return { lat: latitude, lng: longitude };
};

export const formatPathToPolylinePath = (path: Point[]) => {
  return path.map((point) => {
    return formatPointToMapPoint(point);
  });
};

export const getTraceIntervalForMp = (trace: PointWithMp[], mp: number) => {
  let startPoint: PointWithMp | null = null;
  let endPoint: PointWithMp | null = null;

  for (let pointIndex = 0; pointIndex < trace.length - 1; pointIndex++) {
    const currentPoint = trace[pointIndex];
    const nextPoint = trace[pointIndex + 1];

    if (currentPoint.mp <= mp && nextPoint.mp >= mp) {
      startPoint = currentPoint;
      endPoint = nextPoint;
      break;
    }
  }

  return { startPoint, endPoint };
};

export const getTbmPosition = (
  trace: PointWithMp[],
  tbmMp: number,
): {
  tbm: PointWithMp;
  positionInterval: { startPoint: PointWithMp; endPoint: PointWithMp } | null;
} | null => {
  if (trace.length < 2) {
    return null;
  }

  if (tbmMp < trace[0].mp) {
    return {
      tbm: trace[0],
      positionInterval: { startPoint: trace[0], endPoint: trace[1] },
    };
  }

  const lastIndex = trace.length - 1;
  if (tbmMp > trace[lastIndex].mp) {
    return {
      tbm: trace[lastIndex],
      positionInterval: {
        startPoint: trace[lastIndex - 1],
        endPoint: trace[lastIndex],
      },
    };
  }

  const { startPoint, endPoint } = getTraceIntervalForMp(trace, tbmMp);
  if (!startPoint || !endPoint) {
    return null;
  }

  const traceIntervalVector = {
    x: endPoint.longitude - startPoint.longitude,
    y: endPoint.latitude - startPoint.latitude,
  };

  const lastMpDistanceRatio =
    (tbmMp - startPoint.mp) / (endPoint.mp - startPoint.mp);

  // Compute the exact position between the two points in case the TBM is not at a round metric point
  const exactPosition = {
    longitude:
      startPoint.longitude + traceIntervalVector.x * lastMpDistanceRatio,
    latitude: startPoint.latitude + traceIntervalVector.y * lastMpDistanceRatio,
  };

  const tbmPosition = {
    mp: tbmMp,
    ...exactPosition,
  };

  return { tbm: tbmPosition, positionInterval: { startPoint, endPoint } };
};

export const getAngle = (startPoint: Point, endPoint: Point): number => {
  const traceIntervalVector = {
    x: endPoint.longitude - startPoint.longitude,
    y: endPoint.latitude - startPoint.latitude,
  };

  const vectorAngle = Math.atan2(traceIntervalVector.y, traceIntervalVector.x);

  return vectorAngle;
};
