import { Flex } from 'antd';
import { useTranslation } from 'translations/hooks';

import DailyAdvancementContainer from 'business/dashboard/components/daily-advancement-container';
import PerformanceContainer from 'business/dashboard/components/performance-container';
import ProgressHistoryContainer from 'business/dashboard/components/progress-history-container';
import { TunnelBoringMachineContainer } from 'business/dashboard/components/tunnel-boring-machine-container';
import Card from 'ui/card';

import styles from './index.module.scss';

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <Flex className={styles.page}>
      <div className={styles.grid}>
        <div className={styles.tunnelBoringMachine}>
          <Card className={styles.card}>
            <TunnelBoringMachineContainer />
          </Card>
        </div>
        <div className={styles.dailyProgress}>
          <Card className={styles.card}>
            <Card.Header title={t('dashboard.dailyAdvancement.title')} />
            <DailyAdvancementContainer />
          </Card>
        </div>
        <div className={styles.progressHistory}>
          <Card className={styles.card}>
            <Card.Header title={t('dashboard.progressHistory.title')} />
            <ProgressHistoryContainer />
          </Card>
        </div>
        <div className={styles.performance}>
          <Card className={styles.card}>
            <Card.Header title={t('dashboard.performance.title')} />
            <PerformanceContainer />
          </Card>
        </div>
      </div>
    </Flex>
  );
};

export default DashboardPage;
