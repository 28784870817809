import { PlotterMode } from 'business/data-analysis/constants';
import {
  DataAnalysisControllerGraphByPeriodVariables,
  DataAnalysisControllerGraphByRingVariables,
  useDataAnalysisControllerGraphByPeriod,
  useDataAnalysisControllerGraphByRing,
} from 'generated/apiComponents';

import { useQueryParams } from './use-query-params';

export const useGetGraphValues = (
  queryParams: ReturnType<typeof useQueryParams>,
) => {
  const {
    mutateAsync: fetchGraphValuesByPeriod,
    isPending: isFetchingGraphValuesByPeriod,
  } = useDataAnalysisControllerGraphByPeriod();

  const {
    mutateAsync: fetchGraphValuesByRing,
    isPending: isFetchingGraphValuesByRing,
  } = useDataAnalysisControllerGraphByRing();

  const getUpdatedGraphByMode = async (mode: PlotterMode) => {
    if (mode === PlotterMode.TIME_PERIOD) {
      const graphByPeriodResponse = await fetchGraphValuesByPeriod(
        queryParams as DataAnalysisControllerGraphByPeriodVariables,
      );
      if (!graphByPeriodResponse) {
        return null;
      }

      return graphByPeriodResponse;
    }

    if (mode === PlotterMode.RING) {
      const graphByRingResponse = await fetchGraphValuesByRing(
        queryParams as DataAnalysisControllerGraphByRingVariables,
      );
      if (!graphByRingResponse) {
        return null;
      }

      return graphByRingResponse;
    }

    return null;
  };

  return {
    getUpdatedGraphByMode,
    isFetching: isFetchingGraphValuesByPeriod || isFetchingGraphValuesByRing,
  };
};
