import { useCallback, useMemo } from 'react';
import { useTranslation } from 'translations/hooks';

import { createNumberFormatter } from 'business/production-and-performance/pages/ring-table/services/number-format';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

/**
 * Custom hook to format metric points
 */
export const useFormatMetricPoint = () => {
  const { language } = useTranslation();

  // Memoize formatter so it's only recreated when the language changes
  const numberFormatter = useMemo(
    () => createNumberFormatter(language),
    [language],
  );

  const formatNumber = useCallback(
    (value: number | null | undefined): string => {
      if (isUndefinedOrNull(value)) {
        return '';
      }
      return numberFormatter.format(value);
    },
    [numberFormatter],
  );

  return { formatMetricPoint: formatNumber };
};
