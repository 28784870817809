import { useMemo } from 'react';

import { TRACE_DEFAULT_COLOR } from 'business/dashboard/components/map/constants';
import {
  getTbmPosition,
  getAngle,
} from 'business/dashboard/components/map/services';
import { GetTbmInfosQueryResult } from 'generated/graphql';

export const useData = (data: GetTbmInfosQueryResult['data']) => {
  return useMemo(() => {
    const constructionSite = data?.constructionSite[0];
    if (!constructionSite) {
      return null;
    }

    const mapConfig = constructionSite.mapConfiguration ?? {
      latitude: 0,
      longitude: 0,
      zoom: 0,
      traceColor: TRACE_DEFAULT_COLOR,
    };

    const trace = constructionSite.mapTraces ?? [];
    const lastRingMp = constructionSite.rings.at(0)?.buildEndMetricPoint ?? 0;

    const position = getTbmPosition(trace, lastRingMp) ?? {
      tbm: {
        mp: 0,
        latitude: 0,
        longitude: 0,
      },
      positionInterval: null,
    };

    const angle = position.positionInterval
      ? getAngle(
          position.positionInterval.startPoint,
          position.positionInterval.endPoint,
        )
      : 0;

    return {
      ...mapConfig,
      trace,
      position,
      angle,
      pois: constructionSite.mapPois,
    };
  }, [data?.constructionSite]);
};
