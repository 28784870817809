import invariant from 'tiny-invariant';

import { useGraph } from './../hooks/use-graph';

export const useGraphParameter = (
  graphId: string,
  parameterUniqueId: string,
) => {
  const graph = useGraph(graphId);

  const parameter = graph.parameters.get(parameterUniqueId);
  invariant(parameter);

  return parameter;
};
