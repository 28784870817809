import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import { useGraphSetDispatch } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import Routes from 'config/routes';

const PlotterModeTranslationKeys = {
  [PlotterMode.TIME_PERIOD]: 'dataAnalysis.modeSelection.timePeriod',
  [PlotterMode.RING]: 'dataAnalysis.modeSelection.ring',
};

export const ChangeModeButton = ({ mode }: { mode: PlotterMode }) => {
  const { t } = useTranslation();

  const dispatch = useGraphSetDispatch();

  const toggledMode =
    mode === PlotterMode.RING ? PlotterMode.TIME_PERIOD : PlotterMode.RING;

  const resetGraphSet = () => {
    dispatch({
      type: GraphSetDispatchActions.ResetGraphSet,
      mode: toggledMode,
    });
  };

  return (
    <div>
      <Link to={generatePath(Routes.DataAnalysisGraph, { mode: toggledMode })}>
        <Button
          onClick={resetGraphSet}
          icon={<LinkOutlined />}
          iconPosition="end"
        >
          {t(`${PlotterModeTranslationKeys[toggledMode]}.title`)}
        </Button>
      </Link>
    </div>
  );
};
