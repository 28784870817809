import { message } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { getFetchRange } from 'business/production-and-performance/services/getFetchRange';
import config from 'config';
import { useRingPerDayControllerGenerateRingPerDayExport } from 'generated/apiComponents';
import { downloadFile } from 'technical/print';

export const useRingPerDayExport = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const filters = useProdAndPerfFilters();

  const { mutate: generateExport } =
    useRingPerDayControllerGenerateRingPerDayExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const fetchRange = getFetchRange(filters);

  return {
    generateExport,
    fetchRange,
    messageContextHolder: contextHolder,
  };
};
