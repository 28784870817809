import { Card } from 'antd';
import { useTranslation } from 'translations/hooks';

import ParameterCard from 'business/data-analysis/pages/graph/components/parameter-card';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props {
  graphId: string;
}

const ParameterList = ({ graphId }: Props) => {
  const { t } = useTranslation();
  const graph = useGraph(graphId);

  return graph.parameters.size > 0 ? (
    <Flex column justify="flex-start" className={styles.parameterList}>
      {Array.from(graph.parameters.keys()).map((uniqueId) => {
        return (
          <ParameterCard
            graphId={graphId}
            parameterUniqueId={uniqueId}
            key={uniqueId}
          />
        );
      })}
    </Flex>
  ) : (
    <Card className={styles.missingParameters}>
      {t('dataAnalysis.emptyGraph.helper.noParameter')}
    </Card>
  );
};

export default ParameterList;
