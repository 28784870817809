import { Form } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import dayjs from 'dayjs';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

import { RangeFilters } from './types';

const FILTERS_FORM_NAME = 'production-and-performance-filters-form';

const defaultFilters = {
  filterType: 'date',
  dateRange: [null, null],
  dayMode: 'shift',
  showTotalDurations: undefined,
  durationInterval: null,
  withProjection: false,
} satisfies RangeFilters;

const ProdAndPerfFiltersContext = createContext<RangeFilters>(defaultFilters);

const ProdAndPerfFiltersProvider = ({
  initialFilters,
  noDefaultFor,
  children,
}: PropsWithChildren<{
  initialFilters: RangeFilters;
  noDefaultFor?: DateSelectionTypeEnum[];
}>) => {
  const [filters, setFilters] = useState<RangeFilters>(initialFilters);

  const onFormChange: FormProviderProps['onFormChange'] = (
    formName,
    { forms },
  ) => {
    if (formName === FILTERS_FORM_NAME) {
      const form = forms[formName];

      const dateRange =
        form.getFieldValue('filterType') === 'date' &&
        noDefaultFor?.includes(DateSelectionTypeEnum.Period)
          ? [null, null]
          : [dayjs().subtract(1, 'week'), dayjs()];

      const values = {
        dayMode: 'shift',
        dateRange,
        ringRange: [null, null],
        ...form.getFieldsValue(),
      };

      // Setting default range for both day and ring because Antd Form remove the fields value if the input is
      // not rendered. Since we conditionnaly render the dateRange or ringRange depending on the `filterType`
      // it is required to add them with defaut value to the FiltersProvider. Therefore, we're sure it is always
      // defined when the filterType is changed
      setFilters(values);
      form.setFieldsValue(values);
    }
  };

  return (
    <ProdAndPerfFiltersContext.Provider value={filters}>
      <Form.Provider onFormChange={onFormChange}>{children}</Form.Provider>
    </ProdAndPerfFiltersContext.Provider>
  );
};

const useProdAndPerfFiltersFormName = () => FILTERS_FORM_NAME;

const useProdAndPerfFilters = () => useContext(ProdAndPerfFiltersContext);

export {
  ProdAndPerfFiltersProvider,
  useProdAndPerfFiltersFormName,
  useProdAndPerfFilters,
};
