import invariant from 'tiny-invariant';

import { useGraphSet } from './graph-context/contexts';

export const useGraph = (graphId: string) => {
  const graphSet = useGraphSet();

  const graph = graphSet.structure.graphs.get(graphId);
  invariant(graph);

  return graph;
};
