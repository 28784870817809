import invariant from 'tiny-invariant';

import { DisplayType } from 'business/data-analysis/pages/graph/types';

import { GraphPageState } from './../types';

export const updateGraphDisplayType = (
  graphSet: GraphPageState,
  graphId: string,
  displayType: DisplayType,
): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const graphToModify = graphs.get(graphId);
  invariant(graphToModify, 'Trying to update a non-existing graph');

  graphToModify.displayType = displayType;

  graphs.set(graphId, graphToModify);

  return {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  };
};
