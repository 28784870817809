import { GraphCard } from 'business/data-analysis/pages/graph/components/graph-card';
import { useGraphSet } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';

export const GraphList = () => {
  const { structure } = useGraphSet();

  return (
    <>
      {Array.from(structure.graphs.keys()).map((id) => (
        <GraphCard key={id} graphId={id} />
      ))}
    </>
  );
};
