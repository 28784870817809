import { GraphPageState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';

export const updateLoadingStatus = (
  graphSet: GraphPageState,
  isLoading: boolean,
) => {
  return {
    ...graphSet,
    isLoading,
  };
};
