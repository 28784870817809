import {
  AdvancedMarker,
  ControlPosition,
  MapControl,
  Pin,
} from '@vis.gl/react-google-maps';
import { ConfigProvider, Flex, Form, Switch } from 'antd';

import { GetTbmInfosQuery } from 'generated/graphql';

import styles from './index.module.scss';

const SHOW_POIS_FORM_ITEM = 'showPoisFormItem';

interface PointsIfInterestProps {
  pois: GetTbmInfosQuery['constructionSite'][number]['mapPois'];
}

export const PointsOfInterest = ({ pois }: PointsIfInterestProps) => {
  const [form] = Form.useForm();
  const showPois = Form.useWatch(SHOW_POIS_FORM_ITEM, form);

  return (
    <>
      <MapControl position={ControlPosition.LEFT_BOTTOM}>
        <Flex className={styles.poiControl}>
          <ConfigProvider
            theme={{
              components: {
                Form: { itemMarginBottom: 0, verticalLabelPadding: 0 },
              },
            }}
          >
            <Form form={form} layout="vertical">
              <Form.Item
                name={SHOW_POIS_FORM_ITEM}
                label={"Points d'ouvrages"}
                className={styles.label}
                initialValue={true}
              >
                <Switch size="small" disabled={!pois.length} />
              </Form.Item>
            </Form>
          </ConfigProvider>
        </Flex>
      </MapControl>

      {showPois
        ? pois.map(({ latitude, longitude, name }) => {
            return (
              <AdvancedMarker
                position={{ lat: latitude, lng: longitude }}
                key={name}
              >
                <Pin
                  scale={0.75}
                  background="#78909C"
                  glyphColor="white"
                  borderColor="#607D8B"
                />
              </AdvancedMarker>
            );
          })
        : null}
    </>
  );
};
