import { useMemo } from 'react';

import { COMPLETED_TRACE_COLOR } from 'business/dashboard/components/map/constants';
import { formatPathToPolylinePath } from 'business/dashboard/components/map/services';
import { Point, PointWithMp } from 'business/dashboard/components/map/types';
import { Polyline } from 'technical/map/polyline';

interface CompletedTraceProps {
  path: PointWithMp[];
  lastPoint: PointWithMp;
}

const getCompletedPath = (
  path: PointWithMp[],
  lastPoint: PointWithMp,
): Point[] => {
  if (path.length < 2) {
    return [...path, lastPoint];
  }

  const completedPath: Point[] = [path[0]];
  for (let pointIndex = 0; pointIndex < path.length; pointIndex++) {
    const currentPoint = path[pointIndex];

    if (currentPoint.mp > lastPoint.mp) {
      break;
    }

    completedPath.push(currentPoint);
  }

  completedPath.push(lastPoint);
  return completedPath;
};

export const CompletedTrace = ({ path, lastPoint }: CompletedTraceProps) => {
  const polylinePath = useMemo(() => {
    const completedPath = getCompletedPath(path, lastPoint);
    return formatPathToPolylinePath(completedPath);
  }, [path, lastPoint]);

  return (
    <Polyline
      path={polylinePath}
      strokeWeight={6}
      strokeColor={COMPLETED_TRACE_COLOR}
    />
  );
};
