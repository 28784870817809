// Component declaration for a polyline.
// Based on the implementation from the example from visgl/react-google-maps
// https://github.com/visgl/react-google-maps/blob/41b1c70e2a47613dd6131b3489603a0391ea77b9/examples/geometry/src/app.tsx#L39

import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import type { Ref } from 'react';

export type PolylineRef = Ref<google.maps.Polyline | null>;

function usePolyline(props: google.maps.PolylineOptions) {
  const { path, ...polylineOptions } = props;
  // This is here to avoid triggering the useEffect below when the callbacks change (which happen if the user didn't memoize them)

  const polyline = useRef(new google.maps.Polyline()).current;
  // update PolylineOptions
  useMemo(() => {
    polyline.setOptions(polylineOptions);
  }, [polyline, polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // update the path with the encodedPath
  useMemo(() => {
    if (!path) {
      return;
    }

    polyline.setPath(path);
  }, [polyline, path]);

  // create polyline instance and add to the map once the map is available
  useEffect(() => {
    if (!map) {
      if (map === undefined) {
        // Keep it for usage information
        // eslint-disable-next-line no-console
        console.error('<Polyline> has to be inside a Map component.');
      }

      return;
    }

    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map, polyline]);

  return polyline;
}

/**
 * Component to render a polyline on a map
 */
export const Polyline = forwardRef(
  (props: google.maps.PolylineOptions, ref: PolylineRef) => {
    const polyline = usePolyline(props);

    useImperativeHandle(ref, () => polyline, [polyline]);

    return null;
  },
);
