import isEqual from 'lodash.isequal';

import { GraphPageState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';

export const sameGraphSet = (
  baseGraphSetStructure: GraphPageState['structure'],
  potentiallyUpdatedGraphSetStructure: GraphPageState['structure'],
) => {
  return isEqual(baseGraphSetStructure, potentiallyUpdatedGraphSetStructure);
};
