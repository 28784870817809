import invariant from 'tiny-invariant';

import { GraphPageState } from './../types';

export const removeParameters = (
  graphSet: GraphPageState,
  graphId: string,
  parameterUniqueIds: string[],
): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const graphToModify = graphs.get(graphId);
  invariant(
    graphToModify,
    'Trying to remove a parameter from a non-existant graph',
  );
  parameterUniqueIds.forEach((parameterUniqueId) =>
    graphToModify.parameters.delete(parameterUniqueId),
  );

  graphs.set(graphId, graphToModify);

  return {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  };
};
