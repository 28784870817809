import { Row, Col, Statistic } from 'antd';
import { useTranslation } from 'translations/hooks';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

import styles from './index.module.scss';

interface RingPerDayAveragesProps {
  averagePerDay: string | number | null;
  averagePerDayAndShift: string | number | null;
  dayMode: 'shift' | 'calendar' | undefined;
}

export const RingPerDayAverages = ({
  averagePerDayAndShift,
  averagePerDay,
  dayMode,
}: RingPerDayAveragesProps) => {
  const { t } = useTranslation();
  return (
    <Row className={styles.row} gutter={8}>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.ringPerDay.averages.averagePerDay',
          )}
          value={isUndefinedOrNull(averagePerDay) ? 0 : averagePerDay}
        />
      </Col>
      {dayMode === 'shift' ? (
        <Col span={6}>
          <Statistic
            title={t(
              'productionAndPerformance.ringPerDay.averages.averagePerDayAndShift',
            )}
            value={
              isUndefinedOrNull(averagePerDayAndShift)
                ? 0
                : averagePerDayAndShift
            }
          />
        </Col>
      ) : null}
    </Row>
  );
};
