import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'translations/hooks';

import {
  formatRingDuration,
  parseRingDuration,
} from 'business/production-and-performance/services/ring-duration';

type RingDurationProps = {
  rawRingDuration: string | null | undefined;
};

export const RingDuration: React.FC<RingDurationProps> = ({
  rawRingDuration,
}) => {
  const { t } = useTranslation();
  const ringDuration = parseRingDuration(rawRingDuration);

  if (!ringDuration) {
    return <>{'-'}</>;
  }

  if (ringDuration === 'negative') {
    return (
      <Tooltip
        title={t('productionAndPerformance.ring.table.tooltip.duration')}
      >
        <WarningOutlined style={{ color: 'red' }} />
      </Tooltip>
    );
  }

  return <>{formatRingDuration(ringDuration)}</>;
};
