import { GraphSetTypeEnum_Enum } from 'generated/graphql';

import { GraphPageState, PeriodParameter, RingParameter } from './types';

export const isRingParameter = (
  graphSet: GraphPageState,
  _parameter: RingParameter | PeriodParameter,
): _parameter is RingParameter => {
  return graphSet.structure.type === GraphSetTypeEnum_Enum.Ring;
};
