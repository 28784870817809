import { Form, InputNumber, Space } from 'antd';
import { useTranslation } from 'translations/hooks';

import { DurationInputProps } from './types';

export const DurationInput: React.FC<DurationInputProps> = ({
  value, // value for controlled input
  defaultValue = { hours: 0, minutes: 0, seconds: 0 }, // defaultValue for uncontrolled input
  onChange,
  id: namePrefix = 'duration',
  withSeconds = false,
}) => {
  const { t } = useTranslation();

  const triggerChange = (
    changedValue: Partial<{ hours: number; minutes: number; seconds: number }>,
  ) => {
    if (onChange) {
      const newValue = { ...defaultValue, ...value, ...changedValue };
      onChange(newValue);
    }
  };

  return (
    <Space>
      <Form.Item
        label={t('time.duration.hours')}
        name={[namePrefix, 'hours']}
        initialValue={defaultValue.hours}
      >
        <InputNumber
          min={0}
          step={1}
          precision={0}
          value={value?.hours}
          onChange={(hours) => triggerChange({ hours: hours ?? 0 })}
          placeholder={t('time.duration.hours')}
        />
      </Form.Item>

      <Form.Item
        label={t('time.duration.minutes')}
        name={[namePrefix, 'minutes']}
        initialValue={defaultValue.minutes}
      >
        <InputNumber
          min={0}
          max={59}
          step={1}
          precision={0}
          value={value?.minutes}
          onChange={(minutes) => triggerChange({ minutes: minutes ?? 0 })}
          placeholder={t('time.duration.minutes')}
        />
      </Form.Item>

      {withSeconds ? (
        <Form.Item
          label={t('time.duration.seconds')}
          name={[namePrefix, 'seconds']}
          initialValue={defaultValue.seconds}
        >
          <InputNumber
            min={0}
            max={59}
            precision={3}
            value={value?.seconds}
            onChange={(seconds) => triggerChange({ seconds: seconds ?? 0 })}
            placeholder={t('time.duration.seconds')}
          />
        </Form.Item>
      ) : null}
    </Space>
  );
};
